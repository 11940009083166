import {
  Radio as MUIRadio,
  RadioProps,
} from '@mui/material'
import RadioIcon from '../../../../assets/icons/RadioIcon';

export default function Radio(props: RadioProps) {
    const isDisabled = props.disabled || false;
  return (
    <MUIRadio
        checkedIcon={<RadioIcon isChecked={true} isDisabled={isDisabled}/>}
        icon={<RadioIcon isChecked={false} isDisabled={isDisabled}/>}
        {...props}
    />
  )
}