import { FC, useContext, useState } from 'react';
import styles from './styles.module.scss';
import { KioskContext } from '../../../context/KioskContext';
import { Badge, Menu as BaseMenu, styled, useTheme, MenuItem as BaseMenuItem } from '@mui/material';
import { KioskActionsEnum, StepsEnum } from '../../../context/KioskContext/types';
import { getLatestHistory, getLocalHistory } from '../../../helpers/localStorageHelper';
import CartIcon from '../../../assets/icons/CartIcon';
import localCartHistory from '../../../models/localCartHistory';
import { formatMoney } from '../../../helpers/formatHelper';

interface Props {
  disabled?: boolean;
}

const BasketIcon: FC<Props> = ({disabled}) => {
  const theme = useTheme();
  const {state, dispatch} = useContext(KioskContext);

  const itemCount:number = (state.step < StepsEnum.paid && state.cart && state.cart.state.toLowerCase()!=='finalized') ? state.cart.products.length : 0;
  //console.log('BasketIcon', `itemCount:${itemCount}`, state.step, state.cart);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const Menu = styled(BaseMenu)(
    ({ theme }) => `
    background-color: red,
    top: 50px,
    left: 'auto',
    right: 8px,
    `,
  );  
  const MenuItem = styled(BaseMenuItem)(
    ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 8px;
    cursor: default;
    user-select: none;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    `,
  );  

  const basketClicked = (event: any) => {
    if (disabled)return;
    if (itemCount>0) {
      if (state.salePath?.productID){
        dispatch({type: KioskActionsEnum.SELECT_PARENT});
      }
      dispatch({type: KioskActionsEnum.TOGGLE_CART});
    } else {
      const item:localCartHistory|undefined = getLatestHistory();
      if (item){
        dispatch({ type: KioskActionsEnum.OBSERVE_CART, cartId: item.cartId});
      }
      //Show last 10 orders
      //handleClick(event);
    }
  }

  const menuClicked = (event: any) => {
    try {
      if (disabled)return;
      const item:localCartHistory = getLocalHistory()[event.target.value];
      dispatch({ type: KioskActionsEnum.OBSERVE_CART, cartId: item.cartId});
    }
    catch {
    }
    finally {
      handleClose();
    }
  }

  const fixValue = (value:number, length:number, excludeFromStart:number = 0) : string => {
    let result = Math.round(value).toString().padStart(length, '0');
    if (excludeFromStart>0){
      result = result.slice(excludeFromStart);
    }
    return result;
  }

  const formatTime = (value:string) : string => {
    const time:Date = new Date(value);
    const result:string = `${
      fixValue(time.getDate(),2)
    }.${
      fixValue(time.getMonth()+1,2)
    }.${
      fixValue(time.getFullYear(),4,2)
    } ${
      fixValue(time.getHours(),2)
    }.${
      fixValue(time.getMinutes(),2)
    }`;
    //console.log('formatTime',value, result);
    return result;
  }

  return (
    <div className={styles.basketMenu}>
      <div className={styles.basketIcon} onClick={(e) => basketClicked(e)} style={{color: `${theme.palette.text}`}}>
        <Badge badgeContent={itemCount} max={99} color='primary' className={styles.badge}>
          <CartIcon/>
        </Badge>
      </div>      
      <div className={styles.iconMenu}>
        <Menu anchorEl={anchorEl} onClose={handleClose} open={open}>
          {!disabled && getLocalHistory().map((h:localCartHistory, i:number) => 
            <MenuItem key={i} value={i} onClick={(e:any) => menuClicked(e)}>{`${h.serviceID&&`#${h.serviceID}:`} ${formatTime(h.stored)} - ${formatMoney(h.totalAmount)}€`}</MenuItem>
          )}
        </Menu>
      </div>
    </div>      
  );
};

export default BasketIcon;
