import { FC } from 'react';
import styles from './styles.module.scss';

const Shop: FC = () => {

  return (
    <div className={styles.shop} style={{marginLeft:'4vh'}}>
      <h1 style={{marginTop:'2vh'}}>Select Shop:</h1>
      <ul style={{textAlign:'left', marginTop:'2vh'}}>
        <li><a href="/?shop=1">ShopCode 1</a></li>
        <li><a href="/?shop=2">ShopCode 2</a></li>
        <li><a href="/?shop=3">ShopCode 3</a></li>
        <li><a href="/?shop=4">ShopCode 4</a></li>
        <li><a href="/?shop=5">ShopCode 5</a></li>
      </ul>
    </div>
  );
};

export default Shop;
