import Cart from "./cart";

export default class localCartHistory {
  cartId: string;
  serviceID: string;
  stored: string;
  totalAmount: number;

  constructor (cart: Cart){
    this.cartId = cart.id;
    this.serviceID = cart.serviceID;
    this.totalAmount = Cart.getTotalAmount(cart);
    this.stored = new Date().toISOString();
  }
}
