import { useTheme } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

export const CheckBoxIcon: React.FC<{
  isChecked: boolean,
  isDisabled?: boolean,
  isIndeterminate?: boolean,
}> = ({isChecked, isDisabled = false, isIndeterminate = false})  => {
  const theme = useTheme();

  const color = isDisabled ? '#000000' : theme.palette.text.primary;
  const background = isDisabled ? theme.palette.action.disabled : isChecked ? theme.palette.primary.main : 'transparent' ;

  return(
    <div style={
      {
        backgroundColor: background,
        borderColor: theme.palette.text.primary,
        borderRadius: '5px',
        width: '28px',
        height: '28px',
        borderStyle: 'solid',
        borderWidth: '2px',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        color: color,
      }}>
      {(isChecked&&!isIndeterminate)&&<CheckIcon sx={{fill: color}} />}
    </div>
  );
}

export default CheckBoxIcon;
