
export const formatMoney = (amount: number | null | undefined) : string => {
  if (amount === null || amount === undefined)return '';
  return amount.toFixed(2).replaceAll('.',',');
}

export const formatNumber = (value:number, length:number, excludeFromStart:number = 0) : string => {
  let result = Math.round(value).toString().padStart(length, '0');
  if (excludeFromStart>0){
    result = result.slice(excludeFromStart);
  }
  return result;
}

export const formatDate = (date:Date | null | undefined) : string => {
  if (date === null || date === undefined)return '';
  const result:string = `${
    formatNumber(date.getDate(),2)
  }.${
    formatNumber(date.getMonth()+1,2)
  }.${
    formatNumber(date.getFullYear(),4,2)
  }`;
  return result;
}

export const formatTime = (date:Date | null | undefined) : string => {
  if (date === null || date === undefined)return '';
  const result:string = `${
    formatNumber(date.getHours(),2)
  }.${
    formatNumber(date.getMinutes(),2)
  }`;
  return result;
}

export const formatDateTime = (date:Date | null | undefined) : string => {
  if (date === null || date === undefined)return '';
  const result:string = `${formatDate(date)} ${formatTime(date)}`;
  return result;
}

