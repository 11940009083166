import NesteForkAndKnife from "../assets/icons/NesteForkAndKnife";
import RestaurantIcon from '@mui/icons-material/Restaurant';

export enum storeIcons {
    None = 'None',
    ForkAndKnife = 'ForkAndKnife',
    NesteForkAndKnife = 'NesteForkAndKnife'
}
export const IconStore: React.FC<{icon?: storeIcons, iconName?: string}> = ({icon = storeIcons.None, iconName = null})  => {
    const resolveIcon = () : storeIcons => {
        if (icon === storeIcons.None && iconName !== ''){
            try {
                return storeIcons[iconName as keyof typeof storeIcons];
            } catch {
                return storeIcons.None;
            }
        }
        return icon;
    }
    switch (resolveIcon()) {
        case storeIcons.None: {
            return <></>
        }
        case storeIcons.ForkAndKnife: {
            return <RestaurantIcon/>
        }
        case storeIcons.NesteForkAndKnife: {
            return <NesteForkAndKnife/>
        }
    }
}