import { FC } from 'react';
import { CircularProgress, circularProgressClasses, useTheme } from '@mui/material';
import { Box } from '@mui/material';

interface Props {
}

const WaitSign: FC<Props> = () => {

  const theme = useTheme();

  return (
    <Box sx={{
      position: 'relative',
       }}>
      <CircularProgress
        variant="determinate"
        color='primary'
        size={60}
        thickness={6}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        //color='primary'
        sx={{
          color: `${theme.palette.background.default}`,
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={62}
        thickness={6}
      />
    </Box>
  );
};

export default WaitSign;
