import { FC, useContext, useEffect, useState, memo, useCallback } from 'react';
import styles from './styles.module.scss';
import { KioskContext } from '../../../context/KioskContext';
import { getLatestCartCompleted, getLatestCartId, initializeCartState, removeCartState, setLatestCartId, updateCartState } from '../../../helpers/localStorageHelper';
import InactivityTimer from '../../../hooks/InactivityTimer';
import ScreenSize from '../../components/Controls/ScreenSize';
import Config from '../../../hooks/Config';
import Main from '../../components/Main';
import SalePath from '../../../models/SalePath';
import { getSalePaths } from '../../../helpers/searchApi';
import Cart from '../../../models/cart';
import { getCart, initializeCart, setTableNumber } from '../../../helpers/basketApi';
import { KioskActionsEnum } from '../../../context/KioskContext/types';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
//import ChangeCartId from '../../components/Controls/ChangeCartId'; 

interface Props {
  shopCode: string;
  tableNumber: string|null;
  cartId: string|null;
}
interface MemoProps {
  shopCode: string;
  tableNumber: string|null;
  cartId: string|null;
  updated: Date;
}

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
})); 

const AppContent = memo<MemoProps>(({shopCode, tableNumber, cartId, updated}) => {
  // Outside of rendering executed by 1 sec. timer
  //console.log('Render content');
  return (
    <>
      {/* <Header /> */}
      <Main shopCode={shopCode} tableNumber={tableNumber} cartId={cartId} />
    </>
  );
});

const Basket: FC<Props> = ({shopCode, tableNumber, cartId}) => {
  const {state, dispatch} = useContext(KioskContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingCart, setIsLoadingCart] = useState<boolean>(false);
  const [contentUpdated, setContentUpdated] = useState<Date>(new Date());
  const [openingHoursCheck, setOpeningHoursCheck] = useState<number>(-1);
  const {inactivitySeconds } = InactivityTimer();
  const { debugMode } = Config();
  const [ refreshing, refresh ] = useState<boolean>(false);
  const {i18n} = useTranslation();

  useEffect(()=>{
    if (refreshing) {
      refresh(false);
    }
  },[refreshing]);

  const fetchSalePaths = useCallback(async (): Promise<void> => {
    dispatch({type: KioskActionsEnum.SELECT_ROOT});
    const data: SalePath[] = await getSalePaths(i18n.language);
    dispatch({type: 'LOADED', salePaths: data});
    setIsLoading(false);
    setContentUpdated(new Date());
  }, [dispatch, i18n.language])

  const initializeEmptyCart = useCallback(async (): Promise<void> => {
    if (isLoadingCart)return;
    setIsLoadingCart(true);
    if (state.settings!=null){
      const cart: Cart = await initializeCart();
      setLatestCartId(cart.id);
      initializeCartState(cart.id, tableNumber||'');      
      dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: cart });
      if (tableNumber){
        dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: await setTableNumber(cart.id, tableNumber) });
      }
    }
  }, [dispatch, isLoadingCart, state.settings, tableNumber])

  const resumeCart = useCallback(async (cartId:string): Promise<void> => {
    //console.log('resumeCart', cartId, isLoadingCart, state.settings);
    if (isLoadingCart)return;
    setIsLoadingCart(true);
    if (state.settings!=null){
      try {
        const cart: Cart|null = await getCart(cartId);
        if (cart == null){
          //console.log('CART NOT FOUND');
          removeCartState();
        }
        if (cart){
          /// TODO Failed to find Cart
          dispatch({type: KioskActionsEnum.UPDATE_CART, cart:cart});
          setLatestCartId(cart.id);
          updateCartState(cart);
          // console.log('state', cart.id, cartId, cart.state.toLowerCase());
          if (cart.state.toLowerCase()==='finalized'){
            dispatch({ type: KioskActionsEnum.OBSERVE_CART, cartId: cartId });
          }
        }
      }
      catch {
        dispatch({type: KioskActionsEnum.UPDATE_CART, cart:null});
        setLatestCartId(null);
        removeCartState();
      }
  }
    setIsLoadingCart(false);
  }, [dispatch, isLoadingCart, state.settings])

  const setDefaults = useCallback(async (): Promise<void> => {
    if (state.cart){
      if (tableNumber && state.cart.tableNumber !== tableNumber){
        const cart = await setTableNumber(state.cart.id, tableNumber);
        if (cart==null){
          dispatch({ type: KioskActionsEnum.RESET_CART });
          return;
        }
        dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: cart });
      }
    }
  }, [dispatch, state.cart, tableNumber])

  // Fetch SalePaths
  useEffect((): void => {
    if (!state.isDataLoaded && !isLoading){
      setIsLoading(true);
      fetchSalePaths();
    }
  }, [fetchSalePaths, isLoading, state.isDataLoaded])  

  // Fetch Cart
  useEffect((): void => {
    const fetchCartId:string|null = cartId || getLatestCartId();
    //console.log('FETCH', cartId, fetchCartId, getLatestCartCompleted(), getLatestCartId());
    if ((state.cart==null || (fetchCartId && state.cart.id !== fetchCartId)) && !isLoadingCart){
      if (!state.ignoreLatest && fetchCartId && getLatestCartCompleted()===false){
        try {
          //console.log('RESUME CART', cartId);
          resumeCart(fetchCartId);
        }
        catch {
          //console.log('RESUME CART FAILED');
        }
      } else {
        //console.log('INITIALIZE CART');
        initializeEmptyCart();
      }
      setIsLoadingCart(false);
    }
    setDefaults();
  }, [cartId, dispatch, initializeEmptyCart, isLoadingCart, resumeCart, setDefaults, state.cart, state.ignoreLatest])  

  useEffect((): void => {
    if (!isLoading) {
      //console.log('checkVisibility', state.salePathData);
      state.salePathData?.checkVisibility();
    }
  }, [isLoading, state.salePathData])  
  
  useEffect(() => {
    if (state.salePath == null){
      const minutes:number = new Date().getMinutes();
      if (openingHoursCheck !== minutes){
        setOpeningHoursCheck(minutes);
        if (state.salePathData?.rootItems.length>0){
          state.salePathData?.checkVisibility();
        }
        setContentUpdated(new Date());
      }
    }
  },[inactivitySeconds, openingHoursCheck, state.salePath, state.salePathData]);

  const maxWidth:number|null = 600;

  // Rendered by 1 sec. interval
  return (
    <Root className={styles.basket} style={{maxWidth:`${maxWidth&&`${maxWidth}px`}`}}>
      {debugMode && (
        <>
          <ScreenSize/>
          {/* <ChangeCartId/> */}
          {/* <ThrowExampleException/> */}
        </>
      )}
      <AppContent shopCode={shopCode} tableNumber={tableNumber} cartId={cartId} updated={contentUpdated}/>
    </Root>
  );
};

export default Basket;
