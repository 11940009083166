import {
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
} from '@mui/material'

export enum ButtonTypes {
  Primary,
  Secondary,
  Tertiary,
  Text
}

interface ButtonProps extends MUIButtonProps {
  buttontype?: ButtonTypes
}

export default function Button(props: ButtonProps) {

  const decideColor = () : any => {
    if (props.color)return props.color;
    switch(props.buttontype) {
      case ButtonTypes.Secondary: return 'secondary';
      case ButtonTypes.Tertiary: return 'tertiary';
      case ButtonTypes.Text: return 'secondary';
      default: return 'primary';
    };
  }

  const decideVariant = () : any => {
    if (props.variant)return props.variant;
    switch(props.buttontype) {
      case ButtonTypes.Secondary: return 'contained';
      case ButtonTypes.Tertiary: return 'outlined';
      case ButtonTypes.Text: return 'text';
      default: return 'contained';
    };
  }

  const color: any = decideColor();
  const variant: any = decideVariant();
  
  return (
    <MUIButton color={color} variant={variant} {...props}/>
  )
}