import { DeliveryMethodEnum, OrderStateEnum } from '../helpers/basketApi';
import { formatMoney } from '../helpers/formatHelper';
import Customer from './customer';
import Operator from './operator';
import Parameter from './parameter';
import Payment from './payment';
import Product from './product';
import Shop from './shop';

export default class Cart {
  id: string;

  orderId: string;

  receiptNumber: number;

  products: Array<Product>;

  payments: Array<Payment>;

  customer: Customer;

  posNumber: number;

  operator: Operator;

  receiptInfo: string;

  receiptTotal: number;

  shop: Shop;

  posParameters: Array<Parameter>;

  serviceID: string;

  email: string;

  state: string;

  deliveryMethod: DeliveryMethodEnum;

  pickUpTime: string;

  orderState: OrderStateEnum;

  registrationNumber: string;

  tableNumber: string;

  constructor(
    id: string,
    orderId: string,
    receiptNumber: number,
    products: Array<Product>,
    payments: Array<Payment>,
    customer: Customer,
    posNumber: number,
    operator: Operator,
    receiptInfo: string,
    shop: Shop,
    receiptTotal: number,
    posParameters: Array<Parameter>,
    serviceID: string,
    email: string,
    state: string,
    deliveryMethod: DeliveryMethodEnum,
    pickUpTime: string,
    orderState: OrderStateEnum,
    registrationNumber: string,
    tableNumber: string,
  ) {
    this.id = id;
    this.orderId = orderId;
    this.receiptNumber = receiptNumber;
    this.products = products;
    this.payments = payments;
    this.customer = customer;
    this.posNumber = posNumber;
    this.operator = operator;
    this.receiptInfo = receiptInfo;
    this.shop = shop;
    this.receiptTotal = receiptTotal;
    this.posParameters = posParameters;
    this.serviceID = serviceID;
    this.email = email;
    this.state = state;
    this.deliveryMethod = deliveryMethod;
    this.pickUpTime = pickUpTime;
    this.orderState = orderState;
    this.registrationNumber = registrationNumber;
    this.tableNumber = tableNumber;
  }

  static getTotalAmount = (cart: Cart): number => {
    let amount = 0;
    if (cart.products.length > 0) {
      amount = cart.products.map((x) => x.totalAmount).reduce((a, b) => a + b);
    }
    return amount;
  };

  getTotal = (): string => {
    let amount = 0;
    if (this.products.length > 0) {
      amount = this.products.map((x) => x.totalAmount).reduce((a, b) => a + b);
    }
    return formatMoney(amount);
  };

  getProductCount = (): number => {
    if (this.products.length > 0) {
      return this.products.map((x) => x.quantity).reduce((a, b) => a + b);
    }
    return 0;
  };
}
