import { FC, useContext,  ReactNode } from 'react';
import styles from './styles.module.scss'
import Product from '../../../models/product';
import { KioskContext } from '../../../context/KioskContext';
import BasketProduct from '../BasketProduct';
import Line from '../Controls/Line';
import Cart from '../../../models/cart';

export interface Props {
  cart?: Cart;
  onModify?: (product: Product) => void;
  onRemove?: (product: Product) => void;
}

const BasketProductList: FC<Props> = ({cart=undefined, onModify, onRemove}) => {
  const { state } = useContext(KioskContext);

  const source: Cart|null = cart===undefined?state.cart : cart;

  const doModify = (product: Product) => {
    if (onModify)onModify(product);
  }
  const doRemove = (product: Product) => {
    if (onRemove)onRemove(product);
  }

  const renderItem = (): ReactNode[] | undefined => {
    if (source?.products.length===0)return;
    
    return source?.products.map((i: Product, idx: number) => {
      return (
        <div key={idx}>
          <div className={styles.item}>
            <BasketProduct key={idx} product={i} onModify={onModify?doModify:undefined} onRemove={onRemove?doRemove:undefined} />
          </div>
          <Line long />
        </div>
      );
    });
  }

  return (
    <div className={styles.container}>
      {renderItem()}
    </div>
  );
};

export default BasketProductList;
