export default interface ICartState {
  cartId: string|null;
  deliveryMethod: string|null;
  email: string|null;
  registrationNumber: string|null;
  tableNumber: string|null;
  pickupTime: string|null;
  loyaltyCards: ICartStateLoyaltyCard[];
}

export interface ICartStateLoyaltyCard {
  card: string;
  cardNumber: string|null;
}

export const initialCartState: ICartState = {
  cartId: null,
  deliveryMethod: null,
  email: null,
  registrationNumber: null,
  tableNumber: null,
  pickupTime: null,
  loyaltyCards: [],
};
