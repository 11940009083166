export default interface ILocalSettings {
  latestShopCode: string|null;
  latestCartId: string|null;
  latestCartCompleted: boolean|null;
}

export const initialLocalSettings: ILocalSettings = {
  latestShopCode: null,
  latestCartId: null,
  latestCartCompleted: null
};
