interface ApiConfig {
  apiUrl: string;
  apiKey: string;
}

const apiUrl: string = process.env.REACT_APP_BASKET_URI || '';
const apiKey: string = process.env.REACT_APP_BASKET_KEY || '';

export const getConfig = (): ApiConfig => {
    return {apiUrl: apiUrl, apiKey: apiKey };
};