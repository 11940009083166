import {
  Checkbox as MUICheckbox,
  CheckboxProps,
} from '@mui/material'
import CheckBoxIcon from '../../../../assets/icons/CheckBoxIcon'

export default function Checkbox(props: CheckboxProps) {
    const isDisabled = props.disabled || false;
  return (
    <MUICheckbox
        checkedIcon={<CheckBoxIcon isChecked={true} isDisabled={isDisabled}/>}
        indeterminateIcon={<CheckBoxIcon isChecked={false} isDisabled={isDisabled}/>}
        icon={<CheckBoxIcon isChecked={false} isDisabled={isDisabled}/>}
        {...props}
    />
  )
}