import { FC } from 'react';
import styles from './styles.module.scss'
import useWindowState from '../../../../hooks/useWindowState';

const ScreenSize: FC = () => {

  const windowState = useWindowState();

  return (
    <div className={styles.screenSize}>
      {`${windowState.width} x ${windowState.height} (${windowState.portrait?'P':'L'})`}
    </div>
  );
};

export default ScreenSize;
