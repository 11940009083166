import { FC, useContext } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from "react-i18next";
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Cart from '../../../models/cart';
import { KioskContext } from '../../../context/KioskContext';
import useQueryParams from '../../../hooks/useQueryParams';
import { getLocalDefault, setLocalDefault } from '../../../helpers/localStorageHelper';
import Button, { ButtonTypes } from '../Controls/Button';

interface Props {
  allowToChange?: boolean;
}

const Shop: FC<Props> = ({allowToChange=true}) => {

  const {state} = useContext(KioskContext);
  const navigate = useNavigate();
  const {t} = useTranslation();
  const queryParams = useQueryParams();
  const fixedShop: boolean = queryParams.shopCode != null && queryParams.tableNumber != null;

  const source: Cart|null = state.cart;

  const resolveShopName = () : string => {
    let defValue = getLocalDefault('ShopName', '');
    const cartValue = state.cart?.shop?.shopName || '';
    if (cartValue !== '' && cartValue !== defValue){
      setLocalDefault('ShopName', cartValue);
    }
    return cartValue !== '' ? cartValue : defValue;
  }
  const resolveAddress = () : string | null => {
    let defValue = getLocalDefault('ShopAddress', '');
    const cartValue = state.cart?.shop ? `${source?.shop.streetAddress}, ${source?.shop.city}` : '';
    if (state.cart?.shop){
      if (cartValue !== defValue){
        setLocalDefault('ShopAddress', cartValue);
      }
      return cartValue === '' ? null : cartValue;
    }
    return defValue === '' ? null : defValue;
  }
  const resolvePhoneNumber = () : string | null => {
    let defValue = getLocalDefault('PhoneNumber', '');
    const cartValue = (state.cart?.shop && state.cart?.shop.phone?.length>0) ? `${t('caption.phone')}: ${state.cart?.shop.phone}` : '';
    if (state.cart?.shop){
      if (cartValue !== defValue){
        setLocalDefault('PhoneNumber', cartValue);
      }
      return cartValue === '' ? null : cartValue;
    }
    return defValue === '' ? null : defValue;
  }

  const selectionUrl: string = source?.posParameters?.find(p => p.parameterCode.toLowerCase()==='basketshopurl')?.parameterValue||'';

  const selectShop = () => {
    if (selectionUrl.toLowerCase().startsWith('http')){
      window.location.href = selectionUrl;
    } else {
      navigate(selectionUrl);
    }
  }

  const shopName: string = resolveShopName();
  const address: string | null = resolveAddress();
  const phoneNumber: string | null = resolvePhoneNumber();
  
  return (
    <div className={styles.shop}>
      <div className={styles.shopInfo}>
        <Typography variant="h3" >{t('caption.shop')}</Typography>
        <Typography variant="body1">{shopName}</Typography>
        {address && <Typography variant="body1">{address}</Typography>}
        {phoneNumber && <Typography variant="body1">{phoneNumber}</Typography>}
      </div>
      {(allowToChange && !fixedShop && selectionUrl !== '') && (
        <div className={styles.shopAction}>
          <Button buttontype={ButtonTypes.Primary} onClick={selectShop}>{t('button.change')}</Button>
        </div>
      )}
    </div>
  );
};

export default Shop;
