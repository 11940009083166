import EditItem from "./EditItem";
import EditItemNodeChoice from "./EditItemNodeChoice";

export enum EditItemNodeChildType {
  Node = 'Node',
  SelectOne = 'SelectOne',
  SelectMany = 'SelectMany',
  SelectProperty = 'SelectProperty'
}

export default class EditItemNodeChild {

  editItem : EditItem;
  childType : EditItemNodeChildType;
  choices: EditItemNodeChoice[];
  
  constructor(editItem: EditItem, childType : EditItemNodeChildType) {
    this.editItem = editItem;
    this.childType = childType;
    this.choices = EditItemNodeChild.buildChoices(editItem, childType);
  }

  private static buildChoices = (editItem: EditItem, childType: EditItemNodeChildType): EditItemNodeChoice[] => {
    const result : EditItemNodeChoice[] = [];
    if (childType === EditItemNodeChildType.Node) return result;
    editItem.children.forEach((item: EditItem) => {
      result.push(new EditItemNodeChoice(editItem, item));
    });
    return result;
  };


}
  