import { FC, useRef } from 'react';
import styles from './styles.module.scss';
import { Dialog, DialogContent, DialogTitle, IconButton, Slide, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import React from 'react';
import { TransitionProps } from '@mui/material/transitions';
import Line from '../../Controls/Line';
import useRefDimensions from '../../../../hooks/useRefDimensions';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Button, { ButtonTypes } from '../../Controls/Button';

interface Props {
  visible: boolean;
  title?: string;
  content: string;
  width?: number|null;
  onClose?: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DocumentDialog: FC<Props> = ({visible, title='', content, width=null, onClose}) => {

  const refThis = useRef<HTMLDivElement|null>(null);
  const { height:titleHeight } = useRefDimensions(refThis);

  const {t} = useTranslation();

  const handleClose = (): void => {
    if (onClose) onClose();
  };

  const topMargin:number = 80;
  const heightDialog:number = window.innerHeight - topMargin;
  const heightContent:number = heightDialog - titleHeight;
  
  return (
    <Dialog open={visible} onClose={onClose} className={styles.dialog} style={{height: `${heightDialog}px`, top: `${topMargin}px`, width: `${width?`${width}px`:'100%'}`}} TransitionComponent={Transition} scroll='paper'>
      <DialogTitle ref={refThis}>
        <div className={styles.header}>
          <Typography variant="h1">{title}</Typography>
          <IconButton onClick={handleClose}>
            <CloseOutlinedIcon />
          </IconButton>           
        </div>
        <Line short />
      </DialogTitle>
      <DialogContent style={{height:`${heightContent}px`}}>
        <div className={styles.content}>
          <div className={styles.document}>
            <span dangerouslySetInnerHTML={{__html: content}} />
          </div>
          <div className={styles.footer}>
            <Button buttontype={ButtonTypes.Primary} onClick={onClose} className={styles.button}>{t('button.close')}</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DocumentDialog;
