import { FC, useContext, useEffect, useState } from "react";
import styles from './styles.module.scss';
import { KioskContext } from '../../../context/KioskContext';
import { Typography } from "@mui/material";
import { KioskActionsEnum, StepsEnum } from "../../../context/KioskContext/types";

interface Props {
  logoName: string | undefined
}

export const CompanyLogo: FC<Props> = ({ logoName }) => {
  const {state, dispatch} = useContext(KioskContext);
  const [logoUrl, setLogoUrl] = useState<string>('');


  useEffect((): void => {
    const root: string = process.env.REACT_APP_BASKET_IMAGE_URI || '';
    const url:string = `${root}/BasketMobile/Logo.png`;
    setLogoUrl(url);
  },[]);

  // useEffect((): void => {
  //   const fileExtensions = ['jpg', 'png']
  //   if (logoName) {
  //     for (let i = 0; i < fileExtensions.length; i++) {
  //       try {
  //         import(`../../../assets/images/logo/${logoName}.${fileExtensions[i]}`).then((imageModule:any)=>{
  //           if (imageModule.default) {
  //             setLogoUrl(imageModule.default);
  //             return;
  //           }
  //         }).catch((e)=>{
  //           setLogoUrl(null);
  //         });
  //       } catch (e) {
  //         setLogoUrl(null);
  //       }
  //     }
  //   }
  // }, [logoName]);

  const logoClicked = () => {
    dispatch({ type: KioskActionsEnum.OBSERVE_CART, cartId: null});
    dispatch({ type: KioskActionsEnum.SHOW_RECEIPT, cartId: null});
    if (state.step < StepsEnum.payment || state.step === StepsEnum.paid){
      dispatch({type: KioskActionsEnum.SET_STEP, step:0});
    }
    if (state.salePath && !state.paymentInProcess ){
      dispatch({type: KioskActionsEnum.SELECT_ROOT});
    }
  }

  return (
    <div className={styles.companyName} onClick={logoClicked}>
      {logoUrl.length>0 ?
        <img src={logoUrl} alt={logoName?.replaceAll('_', ' ').toUpperCase()}/>
        :
        <Typography color="primary" variant="h2" >{logoName?.toUpperCase()}</Typography>
      }
    </div>
  );
};