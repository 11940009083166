import { CssBaseline, PaletteMode, ThemeProvider, useMediaQuery } from "@mui/material";
import { FC, createContext, useMemo, useState } from "react";
import { getPalette } from "../themes/palette";
import { getComponents } from "../themes/components";
import Config from "../hooks/Config";

export interface Props {
  children?: React.ReactNode,
}

export const ColorModeContext = createContext({ toggle: () => {}, setLight: () => {}, setDark: () => {}, setDefault: () => {} });

const Themed: FC<Props> = ({children}) => {

  const { debugMode } = Config();
  const defaultMode = useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light';
  const [mode, setMode] = useState<PaletteMode|null>(null);

  const colorMode = useMemo(
    () => ({
      toggle: () => {
        setMode((prevMode) => (prevMode==null ? 'light' : prevMode === 'light' ? 'dark' : null));
      },
      setLight: () => {
        setMode((prevMode) => 'light');
      },
      setDark: () => {
        setMode((prevMode) => 'dark');
      },
      setDefault: () => {
        setMode((prevMode) => null);
      },
    }),
    [],
  );

  const palette = useMemo(() => getPalette(mode||defaultMode), [mode, defaultMode]);
  const components = useMemo(() => getComponents(palette), [palette]);

  if (debugMode) console.log('Theme', mode||defaultMode, mode==null && 'DEFAULT');

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={palette}>
        <ThemeProvider theme={components}>
          <CssBaseline enableColorScheme/>
          {children}
        </ThemeProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );  
}

export default Themed;
