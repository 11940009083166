export const CartIcon: React.FC<{}> = ()  => {
  return(
    <svg width="35px" height="25px" viewBox="0 0 35 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="→→-App-/-Mobile-site" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Artboard" transform="translate(-75, -68)">
                <g id="Group-6" transform="translate(76, 68)">
                    <g id="Icons-/-fastfood" transform="translate(15.6522, 10.4348) scale(-1, 1) translate(-15.6522, -10.4348)translate(5.2174, 0)" fill="currentColor" fillRule="nonzero">
                        <path d="M11.6916996,11.3833992 C11.1857708,10.6719368 10.5177866,10.1778656 9.68774704,9.90118577 C8.85770751,9.62450593 8,9.48616601 7.11462451,9.48616601 C6.22924901,9.48616601 5.3715415,9.62450593 4.54150198,9.90118577 C3.71146245,10.1778656 3.04347826,10.6719368 2.53754941,11.3833992 L11.6916996,11.3833992 Z M0,13.2806324 C0,11.5573123 0.719367589,10.1778656 2.15810277,9.14229249 C3.59683794,8.10671937 5.24901186,7.58893281 7.11462451,7.58893281 C8.98023715,7.58893281 10.6324111,8.10671937 12.0711462,9.14229249 C13.5098814,10.1778656 14.229249,11.5573123 14.229249,13.2806324 L0,13.2806324 Z M0,17.0750988 L0,15.1778656 L14.229249,15.1778656 L14.229249,17.0750988 L0,17.0750988 Z M16.1264822,20.8695652 L16.1264822,18.972332 L17.4545455,18.972332 L18.7826087,5.6916996 L9.72332016,5.6916996 L9.48616601,3.7944664 L14.229249,3.7944664 L14.229249,0 L16.1264822,0 L16.1264822,3.7944664 L20.8695652,3.7944664 L19.3043478,19.4940711 C19.256917,19.8893281 19.083004,20.2173913 18.7826087,20.4782609 C18.4822134,20.7391304 18.1343874,20.8695652 17.7391304,20.8695652 L16.1264822,20.8695652 Z M16.1264822,19.0104743 L17.4545455,19.0104743 L16.1264822,19.0104743 Z M0.948616601,20.8695652 C0.679841897,20.8695652 0.454545455,20.7786561 0.272727273,20.5968379 C0.0909090909,20.4150198 0,20.1897233 0,19.9209486 L0,18.972332 L14.229249,18.972332 L14.229249,19.9209486 C14.229249,20.1897233 14.1383399,20.4150198 13.9565217,20.5968379 C13.7747036,20.7786561 13.5494071,20.8695652 13.2806324,20.8695652 L0.948616601,20.8695652 Z" id="Shape"></path>
                    </g>
                    <path d="M0,19.826087 L1.67570304,24 L30.5463618,24 C31.747338,21.2173913 32.3478261,19.826087 32.3478261,19.826087" id="Path-2" stroke="currentColor" strokeWidth="2"></path>
                </g>
            </g>
        </g>
    </svg>    
  );
  // return(
  //   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 25.96" stroke="currentColor" width="24" height="24" strokeWidth="1">
  //     <defs>
  //       <style>
  //       </style>
  //     </defs>
  //     <polygon points="29.45 25.96 2.25 25.96 0 21.45 1.79 20.55 3.49 23.96 28.29 23.96 30.27 20.5 32 21.5 29.45 25.96"/>
  //     <path d="M23.34,12c-.53-.75-1.24-1.27-2.11-1.56-.88-.29-1.78-.44-2.71-.44s-1.84.15-2.71.44c-.88.29-1.58.81-2.11,1.56h9.65ZM11.01,14c0-1.82.76-3.27,2.28-4.36,1.52-1.09,3.26-1.64,5.23-1.64s3.71.55,5.23,1.64c1.52,1.09,2.28,2.55,2.28,4.36h-15ZM11.01,18v-2h15v2h-15ZM7.31,22c-.42,0-.78-.14-1.1-.41s-.5-.62-.55-1.04l-1.65-16.55h5s0-4,0-4h2v4s5,0,5,0l-.25,2H6.21s1.4,14,1.4,14h1.4v2h-1.7ZM7.61,20h1.4s-1.4,0-1.4,0ZM12.01,22c-.28,0-.52-.1-.71-.29s-.29-.43-.29-.71v-1h15v1c0,.28-.1.52-.29.71s-.43.29-.71.29h-13Z"/>
  //   </svg>
  // );
}

export default CartIcon;