import { FC } from 'react';
import styles from './styles.module.scss';
import EditItem from '../../../../models/EditItem';
import ProductSelectMany from './ProductSelectMany';
import ProductSelectOne from './ProductSelectOne';

interface Props {
  title?: string;
  editItem: EditItem;
  relatedChildren?: EditItem[];
  debug?: boolean;
  onChange?: () => void;
}

const ProductOption: FC<Props> = ({title = undefined, editItem, relatedChildren=[], debug = false, onChange}) => {

  const selectOne:boolean = editItem.salePath.isSelectOne();

  const changed = () => {
    if (onChange)onChange();
  };

  if (debug){
    if (selectOne)
      console.log('Step 1', 'ProductSelectOne', editItem.salePath.name, relatedChildren);
    else
      console.log('Step 2', 'ProductSelectMany', editItem.salePath.name, relatedChildren);
  }

  return (

    <div  className={styles.productOption}>
      {selectOne ? (
        <ProductSelectOne title={title || editItem.salePath.name} editItem={editItem} choices={relatedChildren} onChange={changed}/>
      ) : (
        <ProductSelectMany title={title || editItem.salePath.name} editItem={editItem} choices={relatedChildren} onChange={changed}/>
      )}
    </div>
    );
};

export default ProductOption;
