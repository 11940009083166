import './App.scss';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { KioskContext } from '../context/KioskContext';
import { KioskActionsEnum } from '../context/KioskContext/types';
import Config from '../hooks/Config';
import Shop from './pages/Shop';
import Basket from './pages/Basket';
import useQueryParams from '../hooks/useQueryParams'
import { getLatestShopCode, setLatestCartId, setLatestShopCode } from '../helpers/localStorageHelper';
import ErrorBoundary from './components/ErrorBoundary';
import { handleApplicationError } from '../helpers/loggerApi';
import Checkout from './pages/Checkout';

const App: FC = () => {
  const {state, dispatch} = useContext(KioskContext);
  const [ refreshing, refresh ] = useState<boolean>(false);
  useEffect(()=>{if (refreshing)refresh(false)},[refreshing]);

  const {version, debugMode } = Config();

  const queryParams = useQueryParams();
  var navigateTo:string|null=null;

  const shopCode:string|null = queryParams.shopCode || getLatestShopCode();
  const tableNumber:string|null = queryParams.tableNumber?.toUpperCase() || null;
  const cartId:string|null = queryParams.cartId||null;

  useEffect((): void => {
    if (Boolean(JSON.parse(process.env.REACT_APP_DEBUG||'0'))){
      document.documentElement.setAttribute('debug-mode', 'enabled')
    }
  }, [])

  useEffect(() => {
  } ,[shopCode]);

  if (shopCode){
    if (shopCode !== getLatestShopCode()){
      setLatestShopCode(shopCode);
      dispatch({type: KioskActionsEnum.UPDATE_CART, cart:null});
      setLatestCartId(null);
    }
  } else {
    const url:string = process.env.REACT_APP_SHOPS_URI||'/shop';
    navigateTo = url;
  }

  useEffect((): void => {
    console.log('React', React.version);
  }, []);
  useEffect((): void => {
    console.log('Version', version);
  }, [version]);
  useEffect((): void => {
    if (debugMode){
      console.log('DEBUG ON');
    }
  }, [debugMode]);
  useEffect((): void => {
    if (debugMode){
      if (queryParams.shopCode){
        console.log(`QueryParameter 'Shop': ${queryParams.shopCode}`);
      }
      if (queryParams.tableNumber){
        console.log(`QueryParameter 'Table': ${queryParams.tableNumber}`);
      }
      if (queryParams.cartId){
        console.log(`QueryParameter 'Cart': ${queryParams.cartId}`);
      }
    }
  }, [debugMode, queryParams.shopCode, queryParams.tableNumber, queryParams.cartId]);

  const onError = async (error:Error, info:React.ErrorInfo) => {
    handleApplicationError('Root', state.cart?.id, undefined, error);
  }

  return (
    <div className="App">
      <Router>
        <ErrorBoundary onError={onError}>
            <Routes>
              { navigateTo && <Route path={navigateTo} />}
              <Route path="/" element={ shopCode ? <Basket shopCode={shopCode} tableNumber={tableNumber} cartId={cartId}/> : <Shop/> }/> 
              <Route path="/shop" element={<Shop/>}/>
              <Route path="/checkout" element={<Checkout/>}/>
              <Route path="*" element={<>404</>}/>
            </Routes>
          </ErrorBoundary>
      </Router>
    </div>
  );
}

export default App;
