import { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from "react-i18next";
import { InputBaseComponentProps, TextField, Typography } from '@mui/material';
import { validateLuhn10 } from '../../../../helpers/cardHelper';
import { getCartState, setCartState } from '../../../../helpers/localStorageHelper';
import { LoyaltyCardTypeEnum } from '../../../../helpers/basketApi';

interface Props {
  onLoyaltyCardValidate?: (result: boolean) => void;
}
enum validationMethod {
  None = 0,
  LuhnMod10 = 1,
}

interface LoyaltyCardSpec{
  index: number,
  cardType: LoyaltyCardTypeEnum;
  validation: validationMethod;
  regex:string;
}

const LoyaltyCardEntry: FC<Props> = ({onLoyaltyCardValidate}) => {
  const {t} = useTranslation();
  const [refreshing, refresh] = useState<boolean>(false);

  const resolveLoyaltyCards = () : LoyaltyCardSpec[] => {
    const cards: LoyaltyCardSpec[] = [];
    //const cardString = 'K-Plus;^70324[0-9]{11}$;1|Testikortti;^1234;0';
    const cardString = process.env.REACT_APP_LOYALTYCARDS;
    if (!cardString)return [];
    let index: number = 0;
    cardString.split('|').forEach((cardData: string) => {
      const parts:string[] = cardData.split(';');
      const cardType:LoyaltyCardTypeEnum = parts[0] as LoyaltyCardTypeEnum;
      const regex: string|null = parts.length>0 ? parts[1] : null;
      const validation: number|null = parts.length>1 ? Number.parseInt(parts[2]) : null;
      if (regex){
        cards.push({index: index, cardType: cardType, validation: validation as validationMethod, regex: regex});
        index++;
      }
    });
    return cards;
  }
  const [loyaltyCards] = useState<LoyaltyCardSpec[]>(resolveLoyaltyCards());

  const validateLoyaltyCardValue = (card: string, cardNumber: string) : boolean => {
    const cardInfo = loyaltyCards.find(i => i.cardType===card);

    if (!cardInfo){
      return false;
    }
    if (cardNumber.trim().length===0){
      return true;
    }
    if (cardInfo.regex.length>0){
      if (!new RegExp(cardInfo.regex).test(cardNumber)){
        return false;
      }
    }
    switch (cardInfo.validation){
      case validationMethod.LuhnMod10 : {
        if (!validateLuhn10(cardNumber)){
          return false;
        }
      }
    }
    return true;
  }

  useEffect(()=>{
    if (refreshing){
      refresh(false);
    }
  },[refreshing]);

  const invokeValidation = () => {
    let validates = true;
    getCartState().loyaltyCards.forEach((cardState) => {
      if (!validateLoyaltyCardValue(cardState.card, cardState.cardNumber||'')){
        validates = false;
      }
    });
    if (onLoyaltyCardValidate)
      onLoyaltyCardValidate(validates);
  };

  const handleLoyaltyCardChange = (event: React.ChangeEvent<HTMLInputElement>, card:string) => {
    const value = event.target.value;
    if (value.length<25) {
      const val:string = event.target.value;
      const cartState = getCartState();
      const cardState = cartState.loyaltyCards.find(c => c.card === card);
      if (cardState){
        cardState.cardNumber = val;
        setCartState(cartState);
        invokeValidation();
        refresh(true);
      }
    }
  };

  const useInputProps:InputBaseComponentProps = {inputMode: 'numeric'};
  
  return (
    <div className={styles.loyaltyCardEntry}>
      <div className={styles.loyaltyCardPart}><Typography variant="h2" >{t(`caption.loyaltycard`)}</Typography></div> 
      {t(`caption.loyaltycard-description`).length>0 && <div className={styles.loyaltyCardPart}><Typography variant="body1" >{t(`caption.loyaltycard-description`)}</Typography></div>}
      {getCartState().loyaltyCards.map((cardState, i) => {
        return (
          <div key={i}>
            <div className={styles.loyaltyCardPart}><Typography variant="h3">{t(`caption.loyaltycard-entry`, {name: t(`caption.loyaltycard-name-${cardState.card}`)})}</Typography></div>
            <div className={styles.loyaltyCardPart}>
              <TextField variant="outlined" error={!validateLoyaltyCardValue(cardState.card, cardState.cardNumber||'')} inputProps={useInputProps} className={styles.loyaltyCardText} value={cardState.cardNumber} onChange={(e:React.ChangeEvent<HTMLInputElement>)=> {handleLoyaltyCardChange(e, cardState.card)}}
              autoComplete='off'
              />
            </div>
          </div>
        )
      })}
    </div>
  );
};

export default LoyaltyCardEntry;
