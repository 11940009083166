import { buttonBaseClasses, inputLabelClasses, radioClasses } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { createTheme, Theme, alpha } from "@mui/material/styles";
import { getCompanyConfig } from "../companyConfig/companyConfig";

const font = getCompanyConfig('UI.Font.Default','Montserrat, sans-serif');

// declare module '@mui/material/styles' {
//   interface Theme {
//     background: {
//       disabled: string;
//     };
//   }
//   // allow configuration using `createTheme()`
//   interface ThemeOptions {
//     background?: {
//       disabled?: string;
//     };
//   }
// }

// declare module '@mui/material/styles' {
//   interface Palette {
//     primaryLight: Palette['primary'];
//   }
//   interface PaletteOptions {
//     primaryLight: PaletteOptions['primary'];
//   }
// }


// declare module '@mui/material/Button' {
//   interface ButtonPropsVariantOverrides {
//     primary: true;
//     secondary: true;
//     info: true;
//     alert: true;
//     plain: true;
//   }
// }
// declare module '@mui/material/styles' {
//   interface TypographyVariants {
//     logo: React.CSSProperties;
//   }

//   // allow configuration using `createTheme`
//   interface TypographyVariantsOptions {
//     logo?: React.CSSProperties;
//   }
// }

// // Update the Typography's variant prop options
// declare module '@mui/material/Typography' {
//   interface TypographyPropsVariantOverrides {
//     logo: true;
//   }
// };


declare module "@mui/material" {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

export const getComponents = (palette: any): Theme => {

  const getConfig = (prefix:string, key:string) : string => {
    let result: string = getCompanyConfig(prefix+key,'');
    if (result==='') result = getCompanyConfig(prefix+'Default','');
    return result;
  }
  const getFont = (key:string) : string => {
    return getConfig("UI.Font.", key);
  }
  const getFontSize = (key:string) : string => {
    return getConfig("UI.FontSize.", key);
  }

  const base = createTheme(palette);
  const result = createTheme(base, {
    palette: {
      mode: base.palette.mode
    },
    typography: {
      // allVariants: {
      //   fontFamily: "'Montserrat', sans-serif, cursive",
      //   textTransform: "none",
      // },
      // fontFamily: "'Montserrat', sans-serif, cursive",
      logo: {
        fontSize: '32px',
        fontWeight: 800,
      },
      h1: {
        fontFamily: getFont('h1'),
        fontSize: getFontSize('h1'),
        fontWeight: 700,
      },
      h2: {
        fontFamily: getFont('h2'),
        fontSize: getFontSize('h2'),
        fontWeight: 700,
      },
      h3: {
        fontFamily: getFont('h3'),
        fontSize: getFontSize('h3'),
        fontWeight: 700,
      },
      body1: {
        fontFamily: getFont('body1'),
        fontSize: getFontSize('body1'),
        fontWeight: 500,
      },
      body2: {
        fontFamily: getFont('body2'),
        fontSize: getFontSize('body2'),
      },
      subtitle1: {
        fontFamily: getFont('subtitle1'),
        fontSize: getFontSize('subtitle1'),
      },
      subtitle2: {
        fontFamily: getFont('subtitle2'),
        fontSize: getFontSize('subtitle2'),
      },
    },
    shape: {
      borderRadius: 10,
    },
    components: {

      MuiTypography: {
        //defaultProps: { fontFamily: font },
        styleOverrides: {
          root: {
//            fontFamily: font,
          },
        },
      },
      MuiFormControlLabel: {
        //defaultProps: { fontFamily: font },
        styleOverrides: {
          root: {
            fontFamily: font,
            width: "100%",
            color: base.palette.text,
            '.MuiFormControlLabel-label': {
              width: '100%',
            },
            '.MuiFormControlLabel-label.Mui-disabled': {
              color: base.palette.text,
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            margin: '4px 12px 4px 6px',
          },
        },
      },

      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: base.palette.text.primary,
            margin: '4px 12px 4px 6px',
            [`&.${radioClasses.checked}`]: {
              color: base.palette.text.primary,
              [`&.${radioClasses.disabled}`]: {
                color: base.palette.action.disabled,
              },
            },
            [`&.${radioClasses.disabled}`]: {
              color: base.palette.text.primary,
            },
          },
        },
      },

      MuiInputBase: {
        // defaultProps: { fontFamily: font },
        styleOverrides: {
          root: {
            fontFamily: font,
            color: base.palette.text.primary,
            borderColor: base.palette.text.primary,
          },
        },
      },
      MuiInputLabel: {
        //defaultProps: { fontFamily: font },
        styleOverrides: {
          root: {
            fontFamily: font,
            color: base.palette.text.disabled,
            [`&.${inputLabelClasses.error}`]: {
              color: base.palette.error,
            },
          },
        },
      },
      MuiButtonBase: {
        //defaultProps: { fontFamily: font },
      },
      MuiButton: {
        //defaultProps: { fontFamily: font },
        styleOverrides: {
          root: {
            fontFamily: font,
            width: '100%',
            height: '100%',
            fontSize: 14,
            borderRadius: 8,
            textTransform: 'none',
            minWidth: 30,
            minHeight: 30,
            [`&.${buttonBaseClasses.disabled}`]: { color: base.palette.text.secondary },
          }
        },
        variants: [
          {
            props: { color: "primary", variant: "contained" },
            style: {
              color: base.palette.text.primary,
              backgroundColor: base.palette.primary,
              borderColor: base.palette.primary,
              [`&.${buttonBaseClasses.disabled}`]: {
                color: base.palette.background.default,
                backgroundColor: base.palette.text.disabled,
                borderColor: base.palette.text.disabled,
              },
            }
          },
          {
            props: { color: "primary", variant: "outlined" },
            style: {
              color: base.palette.primary,
              backgroundColor: base.palette.background,
              borderColor: base.palette.primary,
              [`&.${buttonBaseClasses.disabled}`]: {
                color: base.palette.background.default,
                backgroundColor: base.palette.text.disabled,
                borderColor: base.palette.text.disabled,
              },
            }
          },
          {
            props: { color: "primary", variant: "text" },
            style: {
              color: base.palette.text.primary,
              backgroundColor: base.palette.background,
              borderColor: base.palette.background,
              [`&.${buttonBaseClasses.disabled}`]: {
                color: base.palette.text.disabled,
              },
            }
          },
          {
            props: { color: "secondary", variant: "contained" },
            style: {
              color: base.palette.text.primary,
              backgroundColor: base.palette.secondary,
              borderColor: base.palette.secondary,
              [`&.${buttonBaseClasses.disabled}`]: {
                color: base.palette.background.default,
                backgroundColor: base.palette.text.disabled,
                borderColor: base.palette.text.disabled,
              },
            }
          },
          {
            props: { color: "secondary", variant: "outlined" },
            style: {
              color: base.palette.secondary,
              backgroundColor: base.palette.background,
              borderColor: base.palette.secondary,
              [`&.${buttonBaseClasses.disabled}`]: {
                color: base.palette.background.default,
                backgroundColor: base.palette.text.disabled,
                borderColor: base.palette.text.disabled,
              },
            }
          },
          {
            props: { color: "secondary", variant: "text" },
            style: {
              color: base.palette.text.secondary,
              backgroundColor: base.palette.background,
              borderColor: base.palette.background,
              [`&.${buttonBaseClasses.disabled}`]: {
                color: base.palette.text.disabled,
              },
            }
          },
          {
            props: { color: "tertiary", variant: "contained" },
            style: {
              color: base.palette.text.primary,
              backgroundColor: base.palette.background,
              borderColor: base.palette.text.primary,
              [`&.${buttonBaseClasses.disabled}`]: {
                color: base.palette.background.default,
                backgroundColor: base.palette.text.disabled,
                borderColor: base.palette.text.disabled,
              },
            }
          },
          {
            props: { color: "tertiary", variant: "outlined" },
            style: {
              color: base.palette.text.primary,
              backgroundColor: base.palette.background,
              borderColor: base.palette.text.primary,
              [`&.${buttonBaseClasses.disabled}`]: {
                color: base.palette.background.default,
                backgroundColor: base.palette.text.disabled,
                borderColor: base.palette.text.disabled,
              },
            }
          },
          {
            props: { color: "tertiary", variant: "text" },
            style: {
              color: base.palette.text.primary,
              backgroundColor: base.palette.background,
              borderColor: base.palette.background,
              [`&.${buttonBaseClasses.disabled}`]: {
                color: base.palette.text.disabled,
              },
            }
          },
        ],
      },
      MuiBadge: {
        defaultProps: { fontFamily: font },
        styleOverrides: {
          root: {
            width: 24,
            height: 24,

            span: {
              fontSize: '10px',
              height: '15px',
              minWidth: '15px',
              borderRadius: '10px',
              padding: '5px',
              marginRight: '5px'
            },
          }
        },
      },
      MuiDialog: {
        defaultProps: {
          fontFamily: font,
        },
        styleOverrides: {
          root: {
            width: '100%',
            margin: 0,
            justifySelf: 'center',
            '.MuiDialogTitle-root': {
              padding: '8px',
              //padding: '8px 4px 8px 8px',
            },
            '.MuiDialogContent-root': {
              padding: '8px',
              //padding: '8px 4px 8px 8px',
            },            
            // [`&.${dialogTitleClasses.root}`]: { padding: '8px 4px 8px 8px' },
            // [`&.${dialogClasses.container}`]: { padding: '8px 4px 8px 8px' },
            // [`&.${dialogContentClasses.root}`]: { padding: '20px' },
          },
          backdrop: {
            backgroundColor: alpha(base.palette.text.primary, 0.2),
            backgroundImage: "unset",
          },
          paper: {
            maxWidth: '100%',
            maxHeight: '100%',
            width: '100%',
            margin: 0,
            backgroundColor: base.palette.background.default,
            backgroundImage: "unset",
          },
        },
      },

      MuiUseMediaQuery: {
        defaultProps: {
          noSsr: true,
        },
      },

      MuiMenuItem: {
        defaultProps: { fontFamily: font },
        styleOverrides: {
          root: {
            fontSize: 20,
            color: base.palette.text.primary,
            backgroundColor: base.palette.background.default,
            '&.Mui-selected': {
              // borderStyle: 'solid',
              // borderWidth: 1,
              color: base.palette.text.secondary,
              //backgroundColor: base.palette.primary.main,
              backgroundColor: base.palette.primary,
            },
            '&.Mui-selected.Mui-focusVisible': {
              // borderStyle: 'solid',
              // borderWidth: 1,
              color: base.palette.text.secondary,
              //backgroundColor: base.palette.primary.main,
              backgroundColor: base.palette.primary,
            },
            '&:hover': {
              backgroundColor: base.palette.background,
              //BorderColor: base.palette.primary.main,
              BorderColor: base.palette.primary,
              BorderStyle: 'solid',
            },


            //.css-cawtlt-MuiButtonBase-root-MuiMenuItem-root.Mui-selected.Mui-focusVisible
            //.css-cawtlt-MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover
            //.css-cawtlt-MuiButtonBase-root-MuiMenuItem-root.Mui-selected

            "&$selected": {
              backgroundColor: "red",
              "&:hover": {
                backgroundColor: "green"
              }
            },

            // '&:unfocus':{
            //   backgroundColor:'yellow'
            // },
            // '&:focus':{
            //   backgroundColor:'green'
            // },

          }
        }
      },

      MuiSelect: {
        defaultProps: {
          fontFamily: font,
          IconComponent: KeyboardArrowDownIcon,
          // MenuProps: {
          //   MenuListProps: {
          //     style: { padding: 0 }
          //   },
          // },
        },
        styleOverrides: {
          root: {
            // lineHeight: 0,
            // fontSize: '16px',
            // fontWeight: 600,
            // height: '100%',

            // '& .MuiInputBase-input': {
            //   alignContent: 'center',
            //   minHeight: 0,
            //   height: '100%',
            // },
            // '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
            //   paddingRight: 26,
            // },
            // "& fieldset": {
            //   borderStyle: 'none',
            //   borderWidth: 0,
            // },
            // "MuiButtonBase-root-MuiMenuItem-root": {
            //   fontSize: '16px',
            // },
            // '.MuiSvgIcon-root': {
            //   fontSize: '24px',
            // },
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: base.palette.text.primary,
            }
          }
        }
      },

    },
  });
  //console.log('getComponents', 'palette', palette, 'components', result);
  //console.log('getComponents', 'components', result);
  return result;
}
