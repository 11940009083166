import { FC, useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from "react-i18next";
import { FormControlLabel, InputBaseComponentProps, RadioGroup, TextField, Typography } from '@mui/material';
import Radio from '../Radio';
import { DeliveryMethodEnum } from '../../../../helpers/basketApi';
import { KioskContext } from '../../../../context/KioskContext';
import SalePath from '../../../../models/SalePath';
import { formatMoney } from '../../../../helpers/formatHelper';
import SalePathData from '../../../../models/SalePathData';
import useQueryParams from '../../../../hooks/useQueryParams';
import { getCartState, setCartState } from '../../../../helpers/localStorageHelper';

interface Props {
  method?:DeliveryMethodEnum;
  onMethodChange?: (result: DeliveryMethodEnum) => void;
  //registrationNumber?: string;
  onRegistrationNumberChange?: (result: string) => void;
  //tableNumber?: string;
  onTableNumberChange?: (result: string) => void;
  onValidate?: (result: boolean) => void;
}

//const DeliveryMethod: FC<Props> = ({method=undefined, onMethodChange, registrationNumber=undefined, onRegistrationNumberChange, tableNumber=undefined, onTableNumberChange, onValidate}) => {
const DeliveryMethod: FC<Props> = ({method=undefined, onMethodChange, onRegistrationNumberChange, onTableNumberChange, onValidate}) => {

  const queryParams = useQueryParams();
  const fixedTable: string|null = queryParams.tableNumber;

  const {t} = useTranslation();
  //const [selectedValue, setSelectedValue] = useState((method===undefined)?DeliveryMethodEnum.FrontCounter:method);
  const [selectedValue, setSelectedValue] = useState(getCartState().deliveryMethod as DeliveryMethodEnum||DeliveryMethodEnum.FrontCounter);
  const [selectedVehicle, setSelectedVehicle] = useState<string>(getCartState().registrationNumber||'');
  const [selectedTable, setSelectedTable] = useState<string>(getCartState().tableNumber||'');
  //const [selectedTable, setSelectedTable] = useState((tableNumber===undefined||tableNumber==='')?'':tableNumber);
  const {state} = useContext(KioskContext);
  const [curbsideItem] = useState<SalePath|undefined>(SalePathData.getCurbsideItem(state.salePathData));

  const validateVehicle = (vehicle:string) => {
    const item:string=vehicle.trim().toUpperCase();
    if (item.length===0)return false;
    if (item.length>20)return false;
    return true;
  };

  const validateTable = (table:string) => {
    const item:string=table.trim().toUpperCase();
    if (item.length===0)return false;
    if (item.length>20)return false;
    return true;
  };

  const validate = (method:DeliveryMethodEnum, vehicle:string, table:string) => {
    var isInvalid:boolean = false;
    if (method===DeliveryMethodEnum.Curbside && !validateVehicle(vehicle)) {
      isInvalid = true;
    }
    if (method===DeliveryMethodEnum.TableService && !validateTable(table)) {
      isInvalid = true;
    }
    if (onValidate)onValidate(!isInvalid);
  };

  const isMethodInUse = (method: DeliveryMethodEnum) : boolean => {
    if (state.cart?.posParameters){
      const prm = state.cart.posParameters.find(p => p.parameterCode.toLowerCase()===`basketdelivery-${method.toLowerCase()}-inuse`);
      if (prm)return (prm.parameterValue === '1' || prm.parameterValue.toLowerCase() === 'true');
    }
    return true;
  };

  useEffect(()=>{
    validate(selectedValue, selectedVehicle, selectedTable);
  });

  if (!isMethodInUse(selectedValue)){
    const preferredOrder:DeliveryMethodEnum[] = [DeliveryMethodEnum.FrontCounter, DeliveryMethodEnum.Takeaway, DeliveryMethodEnum.TableService, DeliveryMethodEnum.Curbside];
    for (let index = 0; index < preferredOrder.length; index++) {
      const deliveryMethod = preferredOrder[index];
      if (isMethodInUse(deliveryMethod)){
        setSelectedValue(deliveryMethod);
        break;
      }
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //const newMethod:DeliveryMethodEnum = Number.parseInt(event.target.value); 
    const newMethod:DeliveryMethodEnum = event.target.value as DeliveryMethodEnum; 
    setSelectedValue(newMethod);
    const state = getCartState();
    state.deliveryMethod = newMethod;
    setCartState(state);
    //console.log('DeliveryMethod', newMethod);
    if (onMethodChange)onMethodChange(newMethod);
    validate(newMethod, selectedVehicle, selectedTable);
  };
  const handleRegistrationNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toUpperCase();
    if (value.length<20){
      setSelectedVehicle(value);
      const state = getCartState();
      state.registrationNumber = value;
      setCartState(state);
    }
    if (onRegistrationNumberChange)onRegistrationNumberChange(value);
    validate(selectedValue, value, selectedTable);
  };
  const handleTableNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toUpperCase();
    if (value.length<20){
      setSelectedTable(value);
      const state = getCartState();
      state.tableNumber = value;
      setCartState(state);
    }
    if (onTableNumberChange)onTableNumberChange(value);
    validate(selectedValue, selectedVehicle, value);
  };


  const registrationNumberDisabled:boolean = selectedValue!==DeliveryMethodEnum.Curbside;

  const tableNumberDisabled:boolean = selectedValue!==DeliveryMethodEnum.TableService || fixedTable !== null;

  const vehicleInput:InputBaseComponentProps = {inputMode: 'text', };

  const vehicleError:boolean = selectedValue === DeliveryMethodEnum.Curbside && !validateVehicle(selectedVehicle);

  const curbSideLabel:string = `${t(`button.delivery-curbside`)}${(curbsideItem?.salePrice||0) > 0 ? ` + ${formatMoney(curbsideItem?.salePrice)}€`:'' }`;

  const tableError:boolean = selectedValue === DeliveryMethodEnum.TableService && !validateTable(selectedTable);

  const tableServiceLabel:string = `${t(`button.delivery-tableService`)}`;

  return (
    <div className={styles.deliveryMethod}>
      <Typography variant="h2" >{t(`caption.delivery-method`)}</Typography>
      <RadioGroup
        value={selectedValue}
        onChange={handleChange}
      >
        {isMethodInUse(DeliveryMethodEnum.FrontCounter) && <FormControlLabel value={DeliveryMethodEnum.FrontCounter} control={<Radio />} label={<Typography variant='body2'>{t(`button.delivery-pickup`)}</Typography>} className={styles.choice} /> }
        {isMethodInUse(DeliveryMethodEnum.Takeaway) && <FormControlLabel value={DeliveryMethodEnum.Takeaway} control={<Radio />} label={<Typography variant='body2'>{t(`button.delivery-takeaway`)}</Typography>} className={styles.choice} /> }
        {isMethodInUse(DeliveryMethodEnum.Curbside) && (
          <>
            <FormControlLabel value={DeliveryMethodEnum.Curbside} control={<Radio />} label={<Typography variant='body2'>{curbSideLabel}</Typography>} className={styles.choice} />
            {selectedValue === DeliveryMethodEnum.Curbside &&
              <TextField className={styles.entry} error={vehicleError}
                inputProps={vehicleInput} label={t(`caption.vehicle-registration-number`)} disabled={registrationNumberDisabled}  variant="outlined" value={selectedVehicle} onChange={handleRegistrationNumberChange}
                autoComplete='basket platenumber'
                />
            }
          </>
        )}
        {isMethodInUse(DeliveryMethodEnum.TableService) && (
          <>
            <FormControlLabel value={DeliveryMethodEnum.TableService} control={<Radio />} label={<Typography variant='body2'>{tableServiceLabel}</Typography>} className={styles.choice} />
            {selectedValue === DeliveryMethodEnum.TableService &&
              <TextField className={styles.entry} error={tableError}
                inputProps={vehicleInput} label={t(`caption.tableNumber`)} disabled={tableNumberDisabled}  variant="outlined" value={selectedTable} onChange={handleTableNumberChange}
                autoComplete='off'
                />
            }
          </>
        )}
      </RadioGroup>
    </div>
  );
};

export default DeliveryMethod;

//fontSize: theme.typography.body2.fontSize