import SalePath from "../../models/SalePath";
import Cart from "../../models/cart";
import SalePathData from "../../models/SalePathData";
import EditData from "../../models/EditData";
import ILocalSettings from "../../models/localSettings";
import EditItemNode from "../../models/EditItemNode";

export enum KioskActionsEnum {
  LOGIN_USER = 'LOGIN_USER',
  SET_SETTINGS = 'SET_SETTINGS',
  RELOAD = 'RELOAD',
  LOADED = 'LOADED',
  SELECT_ROOT = 'SELECT_ROOT',
  SELECT_PARENT = 'SELECT_PARENT',
  SHOW_BASKET = 'SHOW_BASKET',
  HIDE_BASKET = 'HIDE_BASKET',
  REMOVE_ORDER = 'REMOVE_ORDER',
  BASKET_ITEM_ADD = 'BASKET_ITEM_ADD',
  SELECT_NODE = 'SELECT_NODE',
  SELECT_PREVIOUS_STEP = 'SELECT_PREVIOUS_STEP',
  SELECT_NEXT_STEP = 'SELECT_NEXT_STEP',
  SELECT_STEP = 'SELECT_STEP',
  START_PAYMENT = 'START_PAYMENT',
  PAYMENT_INPROCESS = 'PAYMENT_INPROCESS',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_SUCCESSFUL = 'PAYMENT_SUCCESSFUL',
  ORDER_COMPLETE = 'ORDER_COMPLETE',
  ADD_ORDER_NUMBER = 'ADD_ORDER_NUMBER',
  UPDATE_CART = 'UPDATE_CART',
  TIMEOUT = 'TIMEOUT',
  SET_STEP = 'SET_STEP',
  SET_NEXT_STEP = 'SET_NEXT_STEP',
  SET_PREVIOUS_STEP = 'SET_PREVIOUS_STEP',
  SHOW_CART = 'SHOW_CART',
  HIDE_CART = 'HIDE_CART',
  TOGGLE_CART = 'TOGGLE_CART',
  RESET_CART = 'RESET_CART',
  OBSERVE_CART = 'OBSERVE_CART',
  SHOW_RECEIPT = 'SHOW_RECEIPT',
  SHOW_RECEIPTSTATUS = 'SHOW_RECEIPTSTATUS',
  UPDATE_ADDITIONAL_ITEMS = 'UPDATE_ADDITIONAL_ITEMS',
}

export enum StepsEnum {
  collect = 0,
  cart = 1,
  order = 2,
  payment = 3,
  paid = 4
}

export type KioskReducer = (state: IKioskContext, action: any) => IKioskContext;

export enum OrderStepsEnum {
  STEP_ZERO = 0,
  STEP_ONE = 1,
  STEP_TWO = 2,
  STEP_THREE = 3,
  STEP_FOUR = 4
}

export interface IKioskContext {
  settings: ILocalSettings | null;
  operator: string | null;
  isBrowsing: boolean,
  isDataLoaded: boolean,
  loadedAt: Date | null,
  orderSteps: OrderStepsEnum,
  isConfirmingBasket: boolean,
  subTitleText: string,
  salePathData: SalePathData,
  salePath: SalePath | null,
  selectedNode: EditItemNode | null,
  editData: EditData | null,
  cart: Cart | null,
  step: StepsEnum;
  paymentInProcess: boolean | null,
  paymentPending: boolean | null,
  observedCartId: string | null,
  showReceiptCartId: string | null,
  showReceiptStatusCartId: string | null,
  additionalItems: SalePath[] | null,
  ignoreLatest: boolean,
}