import Time from "./time";

export default class OpeningHours {
    public from: Time;
    public to: Time;
    public pickup: Time|null;

    constructor(timeFrom: string, timeTo: string, timePickup: string|null) {
        this.from = new Time(timeFrom);
        this.to = new Time(timeTo);
        this.pickup = timePickup !== null ? new Time(timePickup) : null;
    };

    public validate(): boolean {
        if (!this.from.validate())return false;
        if (!this.to.validate())return false;
        if (this.pickup !== null && !this.pickup.validate())return false;
        return true;
    }

    public isClosedAllDay(): boolean {
        if (!this.validate())return false;
        if (this.from.getMinutes()===0 && this.to.getMinutes()===0) return true;
        return false;
    }

    public static import(data: string|null): OpeningHours | null {
        if (data==null) return null;
        const elements : string[] = data.split('-');
        if (elements.length !== 2)throw new Error(`Invalid OpeningHours format ('${data}')`);
        const elementsTo : string[] = elements[1].split(',');
        if (elementsTo.length===1) {
            return new OpeningHours(elements[0], elements[1], null);
        } else if (elementsTo.length===2) {
            return new OpeningHours(elements[0], elementsTo[0], elementsTo[1]);
        } else throw new Error(`Invalid OpeningHours format ('${data}')`);
    }

    public export(): string {
        if (!this.validate())throw new Error(`Invalid OpeningHours`);
        return `${this.from.toString()}-${this.to.toString()}${this.pickup!==null ? `,${this.pickup.toString()}`:''}`;
    }

    public isOvernight(): boolean {
        if (!this.validate())throw new Error(`Invalid OpeningHours`);
        return Time.compareTimes(this.from, this.to)>0;
    }

    public toString = () : string => {
        if (!this.validate()) return '<invalid>';
        return `${this.from.toString()} - ${this.to.toString()}${this.pickup!==null ? `, ${this.pickup.toString()}`:''}`;
    }
    public toUIString = () : string => {
        if (!this.validate()) return '';
        return `${this.from.toString('.')} - ${this.to.toString('.')}`;
    }

}