import { FC } from 'react';
import styles from './styles.module.scss';
import { Typography, useTheme } from '@mui/material';
//import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import SalePath from '../../../../models/SalePath';
import { useTranslation } from 'react-i18next';
import { IconStore } from '../../../../icons/iconStorage';
import { getCompanyConfig } from '../../../../companyConfig/companyConfig';

interface Props {
  primary: string;
  secondary?: string;
  salePath?: SalePath;
  onClick?: () => void;
}

const ListItem: FC<Props> = ({primary, secondary=null, salePath=null, onClick}) => {

  const {t} = useTranslation();
  const theme = useTheme();

  const isRoot: boolean = salePath ? salePath.isRoot() : false;
  const isEnabled: boolean = isRoot ? salePath?.openingHours?.isOpen() ?? true : true;
  const openingHours: string = !isEnabled ? salePath?.openingHours?.getOpeningHours().today?.isClosedAllDay() ? t('caption.closed-today'): `${t('caption.open-today')}: ${salePath?.openingHours?.getOpeningHours().today?.toUIString()??''}` : '';

  const clicked = () => {
    if (!isEnabled)return;
    if (onClick)onClick();
  }

  return (
    <div className={styles.listItem} onClick={clicked} style={{backgroundColor: `${theme.palette.secondary.main}`}}>
      <div className={styles.content}>
        <div className={styles.icon}>
          <IconStore iconName={getCompanyConfig('UI.Icons.ListIcon', 'None')}/>
        </div>
        <div className={styles.info}>
          <Typography variant="h3" >{primary}</Typography>
          {openingHours.length>0&&<Typography variant="body1" >{openingHours}</Typography>}
          {secondary&&<Typography variant="body1" >{secondary}</Typography>}
        </div>
      </div>
      {isEnabled && (
        <div className={styles.action}>
          <EastRoundedIcon className={styles.arrow} sx={{color: `${theme.palette.text.secondary}`}}/>
        </div>
      )}
    </div>
  );
};

export default ListItem;
