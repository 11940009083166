import React from 'react';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './i18n/i18n';
import './index.css';
import App from './views/App';
import reportWebVitals from './reportWebVitals';
import { KioskProvider } from './context/KioskContext';
import Themed from './views/Themed';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Themed>
        <KioskProvider>
          <Suspense fallback={<div>loading...</div>}>
            <App/>
          </Suspense>
        </KioskProvider>
      </Themed>
    </LocalizationProvider>
  // </React.StrictMode>
);

reportWebVitals();
