import { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from "react-i18next";
import { InputBaseComponentProps, TextField, Typography } from '@mui/material';
import { getCartState, setCartState } from '../../../../helpers/localStorageHelper';

interface Props {
  // email?: string;
  // onEmailChange?: (result: string) => void;
  onEmailValidate?: (result: boolean) => void;
}

const EmailEntry: FC<Props> = ({onEmailValidate}) => {
  const {t} = useTranslation();

  const defaultEmail = () : string => {
    return getCartState().email||'';
  }

  const [email1, setEmail1] = useState<string>(defaultEmail());
  const [email2, setEmail2] = useState<string>(defaultEmail());

  const validateEmailAddress = (email:string) : boolean => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
  }

  const validateEmail1 = (email:string = email1) : boolean => {
    const isValid:boolean = email.trim().length>0 && validateEmailAddress(email);
    return isValid;
  };
  const validateEmail2 = (email:string = email2) : boolean => {
    const isValid:boolean = email.trim().length>0 && email1.toLowerCase() === email.toLowerCase() && validateEmailAddress(email);
    return isValid;
  };

  const validateEmails = (a:string = email1,b:string = email2) : boolean => {
    return validateEmail1(a) && validateEmail2(b);
  };

  useEffect(()=>{
    validateEmails(email1, email2);
  });

  const isEmailValidated:boolean = validateEmails();

  useEffect(()=>{
    if (onEmailValidate)onEmailValidate(isEmailValidated);
  },[isEmailValidated, onEmailValidate]);

  const handleEmailChange = (a:string = email1,b:string = email2) => {
    //const isValid:boolean = validateEmails(a, b);
    // if (isValid && onEmailChange)
    //   onEmailChange(a);
  };

  const handleEmail1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.length<100) {
      const val:string = event.target.value;
      setEmail1(val);
      const state = getCartState();
      state.email = val;
      setCartState(state);
      handleEmailChange(val, email2);
    }
  };
  const handleEmail2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.length<100) {
      const val:string = event.target.value;
      setEmail2(val);
      handleEmailChange(email1, val);
    }
  };

  const email1Error:boolean = !validateEmail1();
  const email2Error:boolean = !validateEmail2();

  const emailInput:InputBaseComponentProps = {inputMode: 'email'};

  return (
    <div className={styles.emailEntry}>
      <div className={styles.emailPart}><Typography variant="h2" >{t(`caption.contact-info`)}</Typography></div> 
      <div className={styles.emailPart}><Typography variant="body1" >{t(`caption.contact-info-description`)}</Typography></div>
      <div className={styles.emailPart} style={{display:'flex'}}>
        <div className={styles.emailPart}><Typography variant="h3">{t(`caption.contact-info-email1`)}</Typography></div>
        <div className={styles.emailPart}><Typography variant="body1">({t(`caption.mandatory`)})</Typography></div>
      </div>
      <div className={styles.emailPart}><TextField variant="outlined" inputProps={emailInput} error={email1Error} className={styles.emailText} value={email1} onChange={handleEmail1Change} sx={{width:'100%'}} autoComplete='off'/></div>

      <div className={styles.emailPart} style={{display:'flex'}}>
        <div className={styles.emailPart}><Typography variant="h3">{t(`caption.contact-info-email2`)}</Typography></div>
        <div className={styles.emailPart}><Typography variant="body1">({t(`caption.mandatory`)})</Typography></div>
      </div>
      <div className={styles.emailPart}><TextField variant="outlined" inputProps={emailInput} error={email2Error} className={styles.emailText} value={email2} onChange={handleEmail2Change} sx={{width:'100%'}} autoComplete='off'/></div>
    </div>
  );
};

export default EmailEntry;
