import { getConfig } from './apiConfig';
import { handleApiError } from './loggerApi';

const config = getConfig();

export const startPayment = async (cartId: string, language: string = 'fi'): Promise<string> => {
  try {
    const redirect:string = `${window.location.origin}/checkout`
    const response = await fetch(`${config.apiUrl}/paytrail/${cartId}/StartPayment?language=${language}&redirect=${redirect}`, {
    });
    if (response.ok) {
      return await response.text();
    } else {
      handleApiError('startPayment', response, cartId);
      return Promise.reject(await response.json());
    }
  } catch (error) {
    handleApiError('startPayment', error, cartId);
    throw error;
  }
};
