import { useState, useEffect } from 'react';
import useWindowState from './useWindowState';

function useRefDimensions(ref: React.MutableRefObject<HTMLDivElement|null>) {

  const windowState = useWindowState();

  const [dimensions, setDimensions] = useState({ width: 1, height: 1 })
  useEffect(() => {
    if (ref.current) {
      const { current } = ref
      const boundingRect = current.getBoundingClientRect()
      const { width, height } = boundingRect
      setDimensions({ width: Math.round(width), height: Math.round(height) })
    }
  }, [ref, windowState])

  return dimensions
}

export default useRefDimensions;