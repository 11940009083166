export default class Time {
    public timeString: string 

    constructor(timeString: string) {
        this.timeString = timeString;
    };

    public static validate(time: string): boolean {
        if (time.length===0) return false;
        const timeString: string = time.replace('.',':');
        const ind: number = timeString.indexOf(':');
        if (ind === undefined || ind < 0) return false;
        try {
            const elements : string[] = timeString.split(':');
            const hours = Number.parseInt(elements[0]);
            const mins = Number.parseInt(elements[1]);
            if (hours<0||mins<0)return false;
            if (hours>23||mins>59)return false;
            return true;
        } catch (error) {
            return false;
        }
    }
    public static create(timeString: string): Time {
        if (!this.validate(timeString))throw new Error(`Invalid Time format ('${timeString}')`);
        return new Time(timeString);
    }

    public validate(): boolean {
        return Time.validate(this.timeString);
    }

    public getMinutes(): number {
        if (this.timeString.length===0) return 0;
        if (!this.validate())throw new Error(`Invalid Time format ('${this.timeString}')`);
        const str: string = this.timeString.replace('.',':');
        const elements : string[] = str.split(':');
        const hours = Number.parseInt(elements[0]);
        const mins = Number.parseInt(elements[1]);
        return (hours*60)+mins;
    }

    public static compareTimes(a: Time, b:Time): number {
        if (!a.validate()) throw new Error(`Invalid Time format ('${a.timeString}')`);
        if (!b.validate()) throw new Error(`Invalid Time format ('${b.timeString}')`);
        const mA: number = a.getMinutes();
        const mB: number = b.getMinutes();
        return mA < mB ? -1 : mA > mB ? 1 : 0;
    }

    public static compareTimeStrings(a: string, b:string): number {
        const t1 : Time|undefined = this.create(a);
        const t2 : Time|undefined = this.create(b);
        return this.compareTimes(t1,t2);
    }

    public compareTo(time: Time): number {
        return Time.compareTimes(this, time);
    }

    public toString = (separator?: string) : string => {
        if (this.timeString.length===0) return '';
        if (!this.validate()) return '<invalid>';
        const minutes: number = this.getMinutes();
        const h:number = Math.floor(minutes / 60);
        const m:number = minutes-(60*h);
        const pad = (num:number, count:number) => String(num).padStart(count, '0');
        return `${pad(h,2)}${separator??':'}${pad(m,2)}`;
    }
}