import { ErrorInfo, FC, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { KioskContext } from '../../../context/KioskContext';
import styles from './styles.module.scss';
import { useTranslation } from "react-i18next";
import ConceptList from '../ConceptList';
import SalePathListItems from '../SalePathListItems';
import SalePathBreadcrumbs from '../SalePathBreadcrumbs';
import SalePathProduct from '../SalePathProduct';
import BasketDialog from '../Dialogs/BasketDialog';
import Header from '../Header';
import Welcome from '../Welcome';
import Shop from '../Shop';
import { StepsEnum } from '../../../context/KioskContext/types';
import Line from '../Controls/Line';
import Order from '../Steps/Order';
import Paid from '../Steps/Paid';
import Receipt from '../Steps/Receipt';
import ReceiptStatus from '../Steps/ReceiptStatus';
import OpenCart from '../Controls/OpenOrder';
import useWindowState from '../../../hooks/useWindowState';
import { handleApplicationError } from '../../../helpers/loggerApi';
import ErrorBoundary from '../ErrorBoundary';
import Footer from '../Footer';
// import EditItemNode from '../../../models/EditItemNode';
// import EditItem from '../../../models/EditItem';

interface Props {
  shopCode: string;
  tableNumber: string|null;
  cartId: string|null;
}

const Main: FC<Props> = ({shopCode, tableNumber, cartId}) => {
  const {state, dispatch} = useContext(KioskContext);
  const { portrait, width: windowWidth, height: windowHeight } = useWindowState();

  const [ refreshing, refresh ] = useState<boolean>(false);
  const {i18n} = useTranslation();

  const htmlStateKey:string = 'data-state';
  const htmlState:string = document.documentElement.getAttribute(htmlStateKey)||'init';

  const refFooter = useRef<HTMLDivElement|null>(null);

  useEffect((): void => {
    var htmlStateUpdate:string = state.step ? state.step.toString() : '';
    if (htmlState !== htmlStateUpdate){
      document.documentElement.setAttribute(htmlStateKey, htmlStateUpdate);
    }
  }, [htmlState, state.step])

  useEffect(()=>{if (refreshing)refresh(false)},[refreshing]);

  useEffect(()=>{refresh(true)},[i18n.language]);
  useEffect(()=>{
    refresh(true);
  },[state.salePath]);
  useEffect(()=>{
    if (cartId){
      //dispatch({ type: KioskActionsEnum.OBSERVE_CART, cartId: cartId });
    }
  },[dispatch, cartId]);

  const showBasketButton: boolean = htmlState.toLowerCase()==='collect';

  useEffect(()=>{
    refresh(true);
  },[portrait, windowHeight]);

  useEffect(()=>{
    window.dispatchEvent(new Event('resize'));
  },[showBasketButton]);

  const onError = async (area: string, error:Error, info:React.ErrorInfo) => {
    handleApplicationError(area, state.cart?.id, undefined, error);
  }

  const isProductSelected: boolean = state.salePath?.nodeType.toLowerCase() === 'product';
  const isCartActive:boolean = (state.cart&&(state.cart.products.length > 0 && state.cart.state.toLowerCase() !== 'finalized'))||false;
  const showFooter:boolean = ( !isProductSelected );
  const showCartButton:boolean = ( isCartActive && !isProductSelected );
  

  const heightHeader:number = 56;
  const heigthBreadcrumb:number = 50;
  const maxWidth:number|null = 600;
  const width:number = windowWidth ? windowWidth < maxWidth ? windowWidth : maxWidth : maxWidth;

  const widthContainer:number = width - 8 - 8;
  const heightFooter: number = (refFooter.current?.clientHeight || 0) + (showCartButton ? 4 : 0);
  const heightContainer:number = window.innerHeight - heightHeader;
  const heightContent:number = heightContainer - heightFooter;
  const heightBrowsing:number = heightContent - heigthBreadcrumb;

  const showDelimeters: boolean = false;

  const content = () : ReactNode => {
    if (state.showReceiptStatusCartId){
      return <ReceiptStatus cartId={state.showReceiptStatusCartId}/>
    }
    if (state.showReceiptCartId){
      return <Receipt cartId={state.showReceiptCartId}/>
    }
    if (state.observedCartId){
      return <Paid cartId={state.observedCartId}/>
    }
    if (state.step === StepsEnum.order){
      return <Order />;
    }

    if (state.salePath == null)
      console.log('Concepts', showCartButton, heightContainer);
    else {
      if (isProductSelected)
        console.log('SalePathProduct', showCartButton, heightContainer);
      else
      console.log('SalePathListItems', showCartButton, heightContainer);
    }

    return (state.salePath == null) ? (
      <div className={styles.concepts} style={{height:`${heightContent}px`, maxHeight:`${heightContent}px`}}>
        <div className={styles.conceptsHeader}>
          <div className={styles.headerItem}><Welcome/></div>
          <div className={styles.headerItem}><Shop/></div>
          {showDelimeters&&<div className={styles.headerItem}><Line short/></div>}
          </div>
          <ErrorBoundary onError={(error:Error, info:ErrorInfo) => onError('Concepts', error, info)}>
            <div className={styles.conceptsList}>
              <ConceptList/>
            </div>
          </ErrorBoundary>
        </div>
    ) : (
      <div className={styles.browse}>
        <div className={styles.breadcrumbs}>
          <SalePathBreadcrumbs/>
        </div>
        <div>
          {
            (isProductSelected) ? 
              <ErrorBoundary onError={(error:Error, info:ErrorInfo) => onError('SalePathItem', error, info)}>
                <div className={styles.product} style={{height:`${heightBrowsing}px`, minHeight:`${heightBrowsing}px`, maxHeight:`${heightBrowsing}px`, width:`${widthContainer+8}px`}}>
                  <SalePathProduct  />
                </div>
              </ErrorBoundary>
            :
              <ErrorBoundary onError={(error:Error, info:ErrorInfo) => onError('SalePathList', error, info)}>
                <div  className={styles.list} style={{height:`${heightBrowsing}px`, minHeight:`${heightBrowsing}px`, maxHeight:`${heightBrowsing}px`}}>
                  <SalePathListItems />
                </div>
              </ErrorBoundary>
          }
        </div>
      </div>
    );
  }

  // if (state.selectedNode){
  //   console.log('Main', 'EditItemNode', state.selectedNode);
  // }

  return (
    <div className={styles.root} style={{maxWidth:`${width&&`${width}px`}`}}>
      <div className={styles.header} id='header'>
        <Header/>
      </div>

      <div className={styles.mainContainer} style={{height:`${heightContainer}px`, maxHeight:`${heightContainer}px`, width:`${widthContainer}px`, maxWidth:`${widthContainer}px`}}>
        <div className={styles.content} id='content'>
          {content()}
        </div>
        <div style={{height: 'auto'}}></div>
        {showFooter && (
        <div ref={refFooter} className={styles.footerContent} style={{width:`${width}px`}}>
          {showCartButton ? (
            <div className={styles.cartButton}>
              <OpenCart />
            </div>
          ) : (
            <div className={styles.footer}>
              <Footer />
            </div>
          )}
        </div>
        )}
      </div>
      <BasketDialog visible={state.step === StepsEnum.cart} width={width} />
    </div>      
  );
};

export default Main;

