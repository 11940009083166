import { FC } from 'react';
import useTheme from '@mui/material/styles/useTheme';


interface Props {
  widthPerc?: number;
  short?: boolean;
  long?: boolean;
}

const Line: FC<Props> = ({widthPerc=100, short=false, long=false}) => {

  const theme = useTheme();
  const color: string = theme.palette.text.primary;

  return (
    <div color='primary' style={{width: `${long?'100%': short?'48px' : widthPerc+'%'}`, backgroundColor: `${color}`, height:'1px' }}/>
  );
};

export default Line;
