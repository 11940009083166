import { FC, ReactNode, useState } from 'react';
import styles from './styles.module.scss';
import Paper from '@mui/material/Paper';
import { Typography, useTheme } from '@mui/material';
import DocumentDialog from '../Dialogs/DocumentDialog';
import { getDocument } from '../../../helpers/searchApi';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import Button from '../Controls/Button';

interface Props {
  disabled?: boolean;
  width?: number|null;
}

const StyledDocumentLink = styled(Button)({
  fontSize: 'small',
  width: 'auto',
  lineHeight: '0',
  paddingLeft: 0,
  paddingRight: 10,
  paddingTop: 0,
  paddingBottom: 0,
  fontWeight: '300'
});

const Footer: FC<Props> = ({disabled, width=null}) => {

  const theme = useTheme();
  const [documentTitle, setDocumentTitle] = useState<string>('');
  const [documentContent, setDocumentContent] = useState<string>('');
  const [showDocument, setShowDocument] = useState<boolean>(false);
  const {t, i18n} = useTranslation();

  const onShowDocument = async (code:string) => {
    const docContent: string = await getDocument(code, i18n.language);
    setDocumentTitle(t(`documents.${code.toLowerCase()}`));
    setDocumentContent(docContent);
    setShowDocument(true);
  }
  const onDocumentClosed = () => {
    setShowDocument(false);
  }

  const copyright:string = process.env.REACT_APP_COPYRIGHT||'© 2024 CGI Inc';
  const documentLink = (code: string) : ReactNode => {
    return (<StyledDocumentLink color='info' variant='text' onClick={() => onShowDocument(code)}>{t(`documents.${code.toLowerCase()}`)}</StyledDocumentLink>);
  }

  return (
    <div className={styles.footer}>
      <Paper square sx={{
        backgroundColor: `${theme.palette.primary.main}`,
        color: `${theme.palette.info.main}`,
        paddingLeft: '5px'
        }}>
        <div className={styles.documents}>
          {documentLink('mobileterms')}
        </div>
      </Paper>
      <Paper square sx={{
        backgroundColor: `black`,
        color: `lightgray`,
        paddingLeft: '5px'
        }}>
          <Typography variant="body2"> {copyright}</Typography>
      </Paper>
      <DocumentDialog visible={showDocument} title={documentTitle} content={documentContent} width={width} onClose={onDocumentClosed}/>
    </div>
  )
};


export default Footer;
