import EditItem from "./EditItem";

export default class EditItemNodeChoice {

  private parent: EditItem;
  item: EditItem;
  showSalePrice: boolean = false;
  
  get name(): string {return this.item.salePath.name;}
  get salePrice(): number {return this.showSalePrice ? this.item.salePath.salePrice : 0;}

  get quantity(): number {return this.item.quantity;}
  set quantity(value: number) {this.item.quantity = value;}
 
  constructor(parent: EditItem, item: EditItem) {
    this.parent = parent;
    this.item = item;
  }
}
  