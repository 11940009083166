import Cart from '../models/cart';
import ICartState, { initialCartState } from '../models/cartState';
import localCartHistory from '../models/localCartHistory';
import localDefaultValue from '../models/localDefaultValue';
import { initialLocalSettings } from '../models/localSettings';
import ILocalSettings from '../models/localSettings';
import { DeliveryMethodEnum, LoyaltyCardTypeEnum } from './basketApi';

// LocalSettings
const keyLocalSettings = 'posmanagerBasketMobileSettings';

export const isLocalSettingsDefined = (): boolean => {
  const settings = getLocalSettings();
  return (settings.latestShopCode != null);
};

export const getLocalSettings = (): ILocalSettings => {
  const json: string | null = localStorage.getItem(keyLocalSettings);
  if (json === null || json.length === 0){
    localStorage.setItem(keyLocalSettings,'');
    return initialLocalSettings;
  }
  return JSON.parse(json);
};

export const removeLocalSettings = (): void => {
  localStorage.removeItem(keyLocalSettings);
};

export const setLocalSettings = (localSettings: ILocalSettings): boolean => {
  try {
    localStorage.setItem( keyLocalSettings, localSettings ? JSON.stringify(localSettings):'');
    return true;
  } catch (e) {
    return false;
  }
};

export const getLatestShopCode = () : string|null => {
  return getLocalSettings().latestShopCode;
};

export const setLatestShopCode = (shopCode: string) => {
  const data:ILocalSettings = getLocalSettings();
  if (shopCode===data.latestShopCode)return;
  data.latestShopCode = shopCode;
  setLocalSettings(data);
};

export const getLatestCartId = () : string | null => {
  return getLocalSettings().latestCartId;
};
export const setLatestCartId = (cartId: string|null, isCompleted:boolean=false) => {
  const data:ILocalSettings = getLocalSettings();
  if (cartId===data.latestCartId)return;
  data.latestCartId = cartId;
  data.latestCartCompleted = isCompleted;
  setLocalSettings(data);
};

export const getLatestCartCompleted = () : boolean => {
  return getLocalSettings().latestCartCompleted || false;
};
export const setLatestCartCompleted = () => {
  const data:ILocalSettings = getLocalSettings();
  data.latestCartCompleted = data.latestCartId==null?null:true;
  setLocalSettings(data);
};


// LocalHistory
const keyLocalHistory = 'posmanagerBasketMobileHistory';

export const isLocalHistoryDefined = (): boolean => {
  const history = getLocalHistory();
  return (history.length>0);
};

export const getLocalHistory = (): localCartHistory[] => {
  const json: string | null = localStorage.getItem(keyLocalHistory);
  if (json === null || json.length === 0){
    localStorage.setItem(keyLocalHistory,JSON.stringify([]));
    return [];
  }
  return JSON.parse(json);
};

export const getLatestHistory = (): localCartHistory|undefined => {
  const history:localCartHistory[] = getLocalHistory();
  if (history&&history.length>0){
    return history[history.length-1];
  }
  return undefined;
};

export const removeLocalHistyory = (): void => {
  localStorage.removeItem(keyLocalHistory);
};

export const addLocalHistory = (cart: Cart) => {
  try {
    const history:localCartHistory[] = purgeHistoryArray(getLocalHistory());
    history.push(new localCartHistory(cart));
    localStorage.setItem( keyLocalHistory, JSON.stringify(history));
    return true;
  } catch (e) {
    return false;
  }
};

const limitCount:number = 10;
const purgeHistoryArray = (history:localCartHistory[]) => {
  //const purged:localCartHistory[] = history.filter(h => h.stored.getDate()>0);
  //return purged;
  if (history.length>=(limitCount-1)){
    return history.toSpliced(0,history.length-limitCount+1);
  }
  return history;
};


// LocalDefaults
const keyLocalDefaults = 'posmanagerBasketMobileDefaults';

export const isLocalDefaultsDefined = (): boolean => {
  const history = getLocalDefaults();
  return (history.length>0);
};

export const getLocalDefaults = (): localDefaultValue[] => {
  const json: string | null = localStorage.getItem(keyLocalDefaults);
  if (json === null || json.length === 0){
    localStorage.setItem(keyLocalDefaults,JSON.stringify([]));
    return [];
  }
  return JSON.parse(json);
};

export const removeLocalDefaults = (): void => {
  localStorage.removeItem(keyLocalDefaults);
};

export const setLocalDefault = (code:string, value:string) => {
  try {
    const items:localDefaultValue[] = getLocalDefaults();
    const item:localDefaultValue|undefined = items.find(i => i.code === code);
    if (item!==undefined){
      item.value = value;
      //console.log('updated item', item);
    } else {
      items.push(new localDefaultValue(code, value));
    }
    //console.log('updated items', items);
    localStorage.setItem( keyLocalDefaults, JSON.stringify(items));
  } catch (e) {
  }
};

export const getLocalDefault = (code:string, defaultValue:string = '') : string => {
  try {
    const items:localDefaultValue[] = getLocalDefaults();
    const item:localDefaultValue|undefined = items.find(i => i.code === code);
    if (item){
      return item.value;
    }
  } catch (e) {}
  return defaultValue;
};

// cartState
const keyCartState = 'posmanagerBasketMobileCartState';

export const isCartStateDefined = (): boolean => {
  const state = getCartState();
  return (state.cartId != null);
};

export const getCartState = (): ICartState => {
  const json: string | null = localStorage.getItem(keyCartState);
  if (json === null || json.length === 0){
    localStorage.setItem(keyCartState,'');
    return initialCartState;
  }
  return JSON.parse(json);
};

export const removeCartState = (): void => {
  localStorage.removeItem(keyCartState);
};

export const setCartState = (cartState: ICartState): boolean => {
  try {
    localStorage.setItem( keyCartState, cartState ? JSON.stringify(cartState):'');
    return true;
  } catch (e) {
    return false;
  }
};

export const initializeCartState = (cartId: string, tableNumber: string = ''): boolean => {
  try {
    const state = initialCartState;
    state.cartId = cartId;
    state.deliveryMethod = tableNumber.length>0 ? DeliveryMethodEnum.TableService : DeliveryMethodEnum.FrontCounter;
    state.email = getLocalDefault('email');
    state.registrationNumber = getLocalDefault('deliveryVehicle');
    state.tableNumber = tableNumber;
    state.pickupTime = '';
    state.loyaltyCards = [];
    if ((process.env.REACT_APP_LOYALTYCARDS||'').toLowerCase().indexOf('plussa')>=0){
      state.loyaltyCards.push({card: LoyaltyCardTypeEnum.Plussa, cardNumber: getLocalDefault('LoyaltyCard_Plussa', '')});
    }
    return setCartState(state);
  } catch (e) {
    return false;
  }
};

export const updateCartState = (cart: Cart): boolean => {
  try {
    const state = getCartState();
    if (state.cartId == null || state.cartId !== cart.id ){
      return initializeCartState(cart.id, cart.tableNumber);
    }
    return true;
  } catch (e) {
    return false;
  }
};

export const finalizeCartState = (cart: Cart): boolean => {
  try {
    const state = getCartState();
    setLocalDefault('email', state.email || cart.email);
    setLocalDefault('deliveryVehicle', state.registrationNumber || cart.registrationNumber);
    state.loyaltyCards.forEach(l => {
      setLocalDefault(`LoyaltyCard_${l.card}`, l.cardNumber||'');
    });
    return true;
  } catch (e) {
    return false;
  }
};
