import { FC, useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from "react-i18next";
import { MenuItem, Select } from '@mui/material';
import { KioskContext } from '../../../../context/KioskContext';
import { getLocalDefault, setLocalDefault } from '../../../../helpers/localStorageHelper';
import { KioskActionsEnum } from '../../../../context/KioskContext/types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface Props {
  disabled?: boolean;
}

const LanguageDropDown: FC<Props> = ({disabled}) => {

  const {dispatch} = useContext(KioskContext);
  const {i18n} = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(getLocalDefault("Language", process.env.REACT_APP_DEFAULT_LANGUAGE));

  useEffect((): void => {
    if (selectedLanguage !== i18n.resolvedLanguage){
      i18n.changeLanguage(selectedLanguage);
      dispatch({ type: KioskActionsEnum.RELOAD });
    }
  }, [dispatch, i18n, selectedLanguage])

  const changed = (e: any): void => {
    const lang = e.target.value;
    setLocalDefault("Language", lang);
    setSelectedLanguage(lang);
  };

  const languageChoices = (): any => {
    const defaultLanguages: string[] = ['fi', 'se', 'en'];
    const languagesInUse: string[] = process.env.REACT_APP_LANGUAGES ? process.env.REACT_APP_LANGUAGES.toLowerCase().split(';') : defaultLanguages;
    return languagesInUse.flatMap((l) => {
      if (defaultLanguages.find((d)=>d===l)===undefined)return null;
      return (<MenuItem key={l.toLowerCase()} value={l.toLowerCase()}>{l.toUpperCase()}</MenuItem>);
    });
  };

  return (
    <div className={styles.language}>
      <Select disabled={disabled} onChange={changed} value={selectedLanguage}
      IconComponent={KeyboardArrowDownIcon}
      MenuProps={{
        MenuListProps: {
          style: { padding: 0 }
        }
      }}
      sx={{
        lineHeight: 0,
        fontSize: '16px',
        fontWeight: 600,
        height: '100%',

        '& .MuiInputBase-input': {
          alignContent: 'center',
          minHeight: 0,
          height: '100%',
        },
        '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
          paddingRight: 26,
        },
        "& fieldset": {
          borderStyle: 'none',
          borderWidth: 0,
        },
        "MuiButtonBase-root-MuiMenuItem-root": {
          fontSize: '16px',
        },
        '.MuiSvgIcon-root': {
          fontSize: '24px',
        },
      }}
      >
        {languageChoices()}
      </Select>
    </div>
  );
};

export default LanguageDropDown;