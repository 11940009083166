import dayjs, { Dayjs } from "dayjs";
import OpeningHours from "./openingHours";
import CurrentOpeningHours from "./currentOpeningHours";

export default class SalePathOpeningHours {

  public monHours: OpeningHours | null;
  public tueHours: OpeningHours | null;
  public wedHours: OpeningHours | null;
  public thuHours: OpeningHours | null;
  public friHours: OpeningHours | null;
  public satHours: OpeningHours | null;
  public sunHours: OpeningHours | null;
  
  constructor(monHours: OpeningHours | null, tueHours: OpeningHours | null, wedHours: OpeningHours | null, thuHours: OpeningHours | null, friHours: OpeningHours | null, satHours: OpeningHours | null, sunHours: OpeningHours | null) {
      this.monHours = monHours;
      this.tueHours = tueHours;
      this.wedHours = wedHours;
      this.thuHours = thuHours;
      this.friHours = friHours;
      this.satHours = satHours;
      this.sunHours = sunHours;

  };
  public static convert(data: any): SalePathOpeningHours|null {
    if (!data)return null;
      return new SalePathOpeningHours(
          OpeningHours.import(data.monday),
          OpeningHours.import(data.tuesday),
          OpeningHours.import(data.wednesday),
          OpeningHours.import(data.thursday),
          OpeningHours.import(data.friday),
          OpeningHours.import(data.saturday),
          OpeningHours.import(data.sunday)
      );
  }

  static weekDayIndex = (date?: Date) : number => {
    const useDate = date === undefined ? new Date() : date;
    const d:Dayjs = dayjs(useDate);
    return d.day();
  }

  static getOpeningHours = (openingHours:SalePathOpeningHours, now:Date = new Date()) : CurrentOpeningHours => {
    const dayIndex: number = SalePathOpeningHours.weekDayIndex(now);

    var ohCurr:OpeningHours|null = null;
    var ohPrev:OpeningHours|null = null;
    switch (dayIndex){
      case 0: {
        ohCurr = openingHours.sunHours;
        ohPrev = openingHours.satHours;
        break;
      }
      case 1: {
        ohCurr = openingHours.monHours;
        ohPrev = openingHours.sunHours;
        break;
      }
      case 2: {
        ohCurr = openingHours.tueHours;
        ohPrev = openingHours.monHours;
        break;
      }
      case 3: {
        ohCurr = openingHours.wedHours;
        ohPrev = openingHours.tueHours;
        break;
      }
      case 4: {
        ohCurr = openingHours.thuHours;
        ohPrev = openingHours.wedHours;
        break;
      }
      case 5: {
        ohCurr = openingHours.friHours;
        ohPrev = openingHours.thuHours;
        break;
      }
      case 6: {
        ohCurr = openingHours.satHours;
        ohPrev = openingHours.friHours;
        break;
      }
      default: { 
        ohCurr = openingHours.monHours;
        ohPrev = openingHours.sunHours;
        break; 
     }
    }
    const result = new CurrentOpeningHours(now, ohCurr, ohPrev);
    return result;
  }
  public getOpeningHours = (now:Date = new Date()) : CurrentOpeningHours => {
    return SalePathOpeningHours.getOpeningHours(this, now);
  }

  public isOpen = (now:Date = new Date()) : boolean => {
    const current:CurrentOpeningHours = this.getOpeningHours(now);
    const minutes:number = (now.getHours()*60)+now.getMinutes();

    if (current.yesterday && current.yesterday.isOvernight()){
      if (minutes<current.yesterday.to.getMinutes())return true;
    }
    if (current.today==null)return true;
    if (current.today.isClosedAllDay())return false;
    return current.today.from.getMinutes() <= minutes && current.today.to.getMinutes() > minutes;
  }

  public allowPickup = (now:Date = new Date()) : boolean => {
    const current:CurrentOpeningHours = this.getOpeningHours(now);
    const minutes:number = (now.getHours()*60)+now.getMinutes();

    if (current.yesterday && current.yesterday.isOvernight()){
      if (minutes<(current.yesterday.pickup||current.yesterday.to).getMinutes())return true;
    }
    if (current.today==null)return true;
    return current.today.from.getMinutes() <= minutes && (current.today.pickup||current.today.to).getMinutes() > minutes;
  }  
}
