import { FC } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from "react-i18next";
import EditItem from '../../../../models/EditItem';
import { Typography } from '@mui/material';

interface Props {
  node: EditItem
}

const ProductSpecialDiets: FC<Props> = ({node}) => {
  const {t} = useTranslation();

  return (
    <>
      {node.salePath.specialDiets.length>0 && 
      <div className={styles.specialDiets}>
        <Typography variant="h3" >{t(`specialDiets.title`)}</Typography>
        <div className={styles.specialDietList}>
          {node.salePath.specialDiets.map((name: string, i: number) => (
            <div key={i} className={styles.specialDiet}>
              <Typography variant="body1" >{t(`specialDiets.${name}_description`)}</Typography>
            </div>
          ))}
        </div>
      </div>
      }
    </>
  );
};

export default ProductSpecialDiets;