import { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import EditItem from '../../../../../models/EditItem';
import { Typography } from '@mui/material';
import ProductSelectManyItem from '../ProductSelectManyItem';
import SalePathDebug from '../../../Controls/SalePathDebug';
import EditItemDebug from '../../../Controls/EditItemDebug';

const showEditItemDebug:boolean = false;
const showSalePathDebug:boolean = false;

interface Props {
  title: string;
  editItem: EditItem;
  choices: EditItem[];
  onChange?: () => void;
}

const ProductSelectMany: FC<Props> = ({title, editItem, choices, onChange}) => {

  const [ selectionArray ] = useState<EditItem[]>(choices.filter(c => c.quantity !== 0));
  const [ refreshing, refresh ] = useState<boolean>(false);

  const freeCount:number = editItem.salePath.chainFreeUnits || 0;

  useEffect(()=>{if (refreshing)refresh(false)},[refreshing]);
  
  const isPriced = (item: EditItem): boolean => {
    //console.log('isPriced', item.salePath.name, item.salePath.isChainProductDefined, freeCount, item.salePath.salePrice, selectionArray);
    //if (item.salePath.isChainProductDefined)return false;
    if (freeCount<1)return item.salePath.salePrice !== 0;
    const index: number = selectionArray.indexOf(item, 0);
    if (index<0){
      if (selectionArray.length>=freeCount) return true;
    } else {
      if (index>=freeCount)return true;
    }
    return false;
  };

  const changed = (item: EditItem, checked: boolean) => {
    if (item.quantity === 0){
      selectionArray.splice(selectionArray.indexOf(item),1);
    } else {
      selectionArray.push(item);
    }
    choices.forEach(c => c.forcedPricing=false);
    for(let i:number=0;i<selectionArray.length;i++){
      selectionArray[i].forcedPricing = freeCount===0? selectionArray[i].salePath.salePrice !== 0 : selectionArray[i].quantity !== 0 && i>=freeCount;
    }
    refresh(true);
    if (onChange)onChange();
  };

  //console.log('ProductSelectMany', title, freeCount, selectionArray.length);

  return (
    <div className={styles.productSelect}>
      {showEditItemDebug && (
      <div style={{marginTop:5}}>
        <EditItemDebug node={editItem} />
      </div>
      )}
      {showSalePathDebug && (
      <div style={{marginTop:5}}>
        <SalePathDebug node={editItem} />
      </div>
      )}

      <Typography variant="h3" >{title}</Typography>
      {choices.map((choice:EditItem, i:number)=>(
        <ProductSelectManyItem key={i} title={choice.salePath.name} editItem={choice} disabled={!choice.salePath.isOptional} showPrice={isPriced(choice)} onChange={changed} />
      ))}
    </div>
    );
};

export default ProductSelectMany;
