import { ErrorInfo, FC, useContext, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { Dialog, DialogContent, DialogTitle, IconButton, Slide, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { KioskContext } from '../../../../context/KioskContext';
import { KioskActionsEnum } from '../../../../context/KioskContext/types';
import Product from '../../../../models/product';
import React from 'react';
import { TransitionProps } from '@mui/material/transitions';
import BasketProductList from '../../BasketProductList';
import { deleteProductRow } from '../../../../helpers/basketApi';
import Line from '../../Controls/Line';
import ConfirmDialog from '../ConfirmDialog';
import BasketProduct from '../../BasketProduct';
import OpenOrder from '../../Controls/OpenOrder';
import useRefDimensions from '../../../../hooks/useRefDimensions';
import ErrorBoundary from '../../ErrorBoundary';
import { handleApplicationError } from '../../../../helpers/loggerApi';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

interface Props {
  visible: boolean;
  width?: number|null;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BasketDialog: FC<Props> = ({visible, width=null}) => {

  const {state, dispatch} = useContext(KioskContext);
  const refThis = useRef<HTMLDivElement|null>(null);
  const { height:titleHeight } = useRefDimensions(refThis);

  const [ productToRemove, setProductToRemove ] = useState<Product|null>(null);
  const {t} = useTranslation();

  const onClose = () => {
    dispatch({type: KioskActionsEnum.HIDE_CART});
    window.dispatchEvent(new Event('resize'));
  }

 const handleChange = (product: Product): void => {
  const salePath = state.salePathData.findBySalePathCode(product.salePathCode);
  dispatch({type: KioskActionsEnum.SELECT_NODE, salePath, product});
  onClose();
}

const handleRemove = (product: Product) => {
  setProductToRemove(product);
}

const confirmRemove = async (remove: boolean) => {
  if (!remove){
    setProductToRemove(null);
    return;
  }
  if (productToRemove)
    removeProduct(productToRemove);
};

const removeProduct = async (product: Product) => {
  if (!product){
    setProductToRemove(null);
    return;
  }
  if (state.cart!=null){
    const cart = await deleteProductRow(state.cart.id, product.rowId);
    if (cart==null){
      dispatch({ type: KioskActionsEnum.RESET_CART });
      return;
    }
    dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: cart });
    setProductToRemove(null);
    if (cart.products.length===0){
      dispatch({ type: KioskActionsEnum.SELECT_ROOT });
      onClose();
    }
  } else {
    setProductToRemove(null);
  }
};

const onError = async (area: string, error:Error, info:React.ErrorInfo) => {
  handleApplicationError(area, state.cart?.id, undefined, error);
}

const heightMargin:number = 80;
const heightContainer:number = window.innerHeight - heightMargin;
const heightList:number = heightContainer - titleHeight;

const showDelimeters: boolean = false;

return (
  <ErrorBoundary onError={(error:Error, info:ErrorInfo) => onError('BasketDialog', error, info)}>
    <Dialog open={visible} onClose={onClose} style={{height: `${heightContainer}px`, top: `${heightMargin}px`, width: `${width?`${width}px`:'100%'}`, backdropFilter: 'none'}} TransitionComponent={Transition} scroll='paper'
      // PaperProps={{ style: { backgroundColor: "transparent" } }}    
      
    >
      <DialogTitle ref={refThis}>
        <div className={styles.titleItem}>
          <div className={styles.header}>
            <Typography variant="h1">{t('caption.basket')}</Typography>
            <IconButton onClick={onClose}>
              <CloseOutlinedIcon />
            </IconButton>           
          </div>
        </div>
        {showDelimeters&&<Line short/>}
        </DialogTitle>
        <DialogContent style={{height:`${heightList}px`}} className={styles.content}>
          <BasketProductList onModify={(i) => handleChange(i)} onRemove={(i) => handleRemove(i)}/>
          <div style={{height:60}}></div>
        </DialogContent>
        <div className={styles.footerContent}>
          <OpenOrder />
        </div>
        <ConfirmDialog visible={productToRemove!=null} title={t('caption.remove-product')} defaultResult={false} width={width} onClose={confirmRemove}>
        <>
          {productToRemove && <BasketProduct disableControls product={productToRemove} />}
        </>
      </ConfirmDialog>
    </Dialog>
  </ErrorBoundary>
  );
};

export default BasketDialog;
