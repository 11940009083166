
export const validateLuhn10 = (value: string) : boolean => {
      if (/[^0-9-\s]+/.test(value)) return false;
      let nCheck: number = 0;
      let nDigit: number = 0;
      let bEven: boolean = false;
      value = value.replace(/\D/g, "");
      for (var n = value.length - 1; n >= 0; n--) {
          const cDigit = value.charAt(n);
          nDigit = parseInt(cDigit, 10);
          if (bEven) {
              if ((nDigit *= 2) > 9) nDigit -= 9;
          }
          nCheck += nDigit;
          bEven = !bEven;
      }
      return (nCheck % 10) === 0;
}

