import { useTheme } from '@mui/material';

export const RadioIcon: React.FC<{
  isChecked: boolean,
  isDisabled?: boolean,
  isIndeterminate?: boolean,
}> = ({isChecked, isDisabled = false, isIndeterminate = false})  => {
  const theme = useTheme();

  const color = isDisabled ? 'black' : theme.palette.text.primary;
  const background = isDisabled ? theme.palette.action.disabled : isChecked ? theme.palette.primary.main : 'transparent' ;

  return(
    <div style={{
      backgroundColor: background,
      borderColor: theme.palette.text.primary,
      borderRadius: '50%',
      width: '28px',
      height: '28px',
      borderStyle: 'solid',
      borderWidth: '2px',
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      placeContent: 'center',
    }}>
      {(isChecked&&!isIndeterminate)&&(
        <div style={{
          width: '60%',
          height: '60%',
          backgroundColor: color,
          borderRadius: '50%',
        }}/>
      )}
    </div>
  );
}

export default RadioIcon;
