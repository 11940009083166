import { FC } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from "react-i18next";
import { Typography } from '@mui/material';

const Welcome: FC = () => {

  const {t} = useTranslation();

  return (
    <div className={styles.welcome}>
        <Typography variant="h1" className={styles.title} >{t('caption.welcome-title')}</Typography>
        <Typography variant="body1" className={styles.story} >{t('caption.welcome')}</Typography>
    </div>
  );
};

export default Welcome;
