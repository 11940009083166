import OpeningHours from "./openingHours";
import Time from "./time";

export default class CurrentOpeningHours {
    public date: Date;
    public today: OpeningHours|null;
    public yesterday: OpeningHours|null;

    constructor(date: Date, today:OpeningHours|null, yesterday:OpeningHours|null) {
        this.date = date;
        this.today = today;
        this.yesterday = yesterday;
    };

    public isOpen = () : boolean => {
        const minutes:number = (this.date.getHours()*60)+this.date.getMinutes();
    
        if (this.yesterday&&this.yesterday.isOvernight()){
          if (minutes<this.yesterday.to.getMinutes())return true;
        }
        if (this.today==null)return true;
        return this.today.from.getMinutes() <= minutes && this.today.to.getMinutes() > minutes;
      }
    
      public allowPickup = () : boolean => {
        const minutes:number = (this.date.getHours()*60)+this.date.getMinutes();
        const yesterdayTime: Time | undefined = this.yesterday?.pickup||this.yesterday?.to;
        const todayTime: Time | undefined = this.today?.pickup||this.today?.to;

        if (this.yesterday && yesterdayTime && this.yesterday.isOvernight()){
          if (minutes<yesterdayTime.getMinutes())return true;
        }
        if (this.today==null||!todayTime)return true;
        const result = this.today.from.getMinutes() <= minutes && (todayTime).getMinutes() >= minutes;
        return result
      }  
    
}