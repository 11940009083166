import { getConfig } from './apiConfig';
import { getLatestShopCode } from './localStorageHelper';

const apiUrl:string = getConfig().apiUrl;

export enum SeverityEnum {
  Debug = 'Debug',
  Info = 'Info',
  Warning = 'Warning',
  Error = 'Error'
}

export interface LogData {
  severity: SeverityEnum,
  message: any,
}


export const handleApiError = async (method:string, response?:Response|Error|any, cartId?:string, args:any = undefined) => {
  console.log('INSIGHT', 'Api Error', 'method', method, 'response', response, 'cartId', cartId, 'args', args);
  function getErrorMessage(error: unknown) : string|null {
    if (!error)return null;
    if (error instanceof Error) return error.message
    return String(error);
  }
  const shopCode:string = getLatestShopCode()||'';
  const responseElement:string|null = (response instanceof Response) ? `${response.status}: ${response.statusText}` : null;
  const errorElement:string|null = (response && !(response instanceof Response)) ? getErrorMessage(response) : null;
  const msg = {
    application: 'BasketMobile',
    shopCode: shopCode,
    area: 'API',
    method:method,
    args:args,
    error: responseElement || errorElement,
  };
  const logData:LogData = {
    severity : SeverityEnum.Error,
    message: JSON.stringify(msg)
  };
  try {
    postLogEntry(logData, cartId);
  }
  catch (e) {
    console.log('INSIGHT', 'Failed to post insight', e, logData);
  }
};

export const handleApplicationError = async (area:string, cartId?:string, description?:string, error?:Error|any) => {
  console.log('INSIGHT', 'Application Error', 'area', area, 'description', description, 'error', error);
  const shopCode:string = getLatestShopCode()||'';
  const msg = {
    application: 'BasketMobile',
    shopCode: shopCode,
    area: area,
    description: description && description,
    message: error && error.message,
    stack: error && error.stack,
  };
  const logData:LogData = {
    severity : SeverityEnum.Error,
    message: JSON.stringify(msg)
  };
  try {
    postLogEntry(logData, cartId);
  }
  catch (e) {
    console.log('INSIGHT', 'Failed to post insight', e, logData);
  }
};


export const postLogEntry = async ( logData: LogData, cartId: string|null|undefined = null ) : Promise<boolean> => {
  try {
    const cartDefinition = cartId ? `/${cartId}` : '';
    const response = await fetch(`${apiUrl}/logger${cartDefinition}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(logData),
    });
    return response.ok;
  } catch {
    return false;
  }
};
