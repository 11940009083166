import { FC } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from "react-i18next";
import EditItem from '../../../../models/EditItem';
import { Typography } from '@mui/material';

interface Props {
  node: EditItem
}

const ProductAllergens: FC<Props> = ({node}) => {
  const {t} = useTranslation();

  const buildAllergensText = () : string => {
    const items: string[] = [];
    node.salePath.allergens.forEach(a => {
      items.push(` ${t(`allergens.${a}_name`)}. `);
    })
    return items.join('');
  }
  return (
    <>
      {node.salePath.allergens.length>0 && 
      <div className={styles.allergens}>
        <Typography variant="h3" >{t(`allergens.title`)}</Typography>
        <div className={styles.allergenList}>
          {buildAllergensText()}
        </div>
      </div>
      }
    </>
  );
};

export default ProductAllergens;