import { FC, useContext, useRef } from 'react';
import styles from './styles.module.scss';
import { KioskContext } from '../../../context/KioskContext';
import { Typography } from '@mui/material';
import SalePath from '../../../models/SalePath';
import SalePathListProduct from '../SalePathListProduct';
import ListItem from '../Controls/ListItem';
import { KioskActionsEnum } from '../../../context/KioskContext/types';

interface Props {
}

const SalePathListItems: FC<Props> = () => {

  const {state, dispatch} = useContext(KioskContext);
  const selected:SalePath|null = state.salePath;
  const refContainer = useRef<HTMLDivElement|null>(null);
  const refThis = useRef<HTMLDivElement|null>(null);

  const categoryClick = (salePath:SalePath) => {
    dispatch({type: KioskActionsEnum.SELECT_NODE, salePath: salePath});
  }

  const concept:SalePath|null = selected?.getRoot()||null;
  if (concept && !concept.isVisible){
    dispatch({type: KioskActionsEnum.SELECT_ROOT});
  }

  return (
    <div ref={refThis} className={styles.salePathListItems}>
      {selected && (
        <div className={styles.header}>
        <Typography variant="h2" >{selected.name}</Typography>
        </div>
      )}
      <div ref={refContainer} className={styles.container}>
        {selected  && SalePath.sort(selected.children).map((n:SalePath, i:number) => (
          <div key={i} className={styles.item}>
            {n.isMainProduct() ? <SalePathListProduct salePath={n}/>:<ListItem primary={n.name} onClick={()=>categoryClick(n)}/>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SalePathListItems;
