import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18n.use(Backend)
  .use(initReactI18next)
  .init({
    ns: ['common'],
    defaultNS: process.env.REACT_APP_COMPANY_NAME?.toLowerCase(),
    fallbackNS: 'common',

    returnNull: false,
    lng: "fi",
    fallbackLng: "fi"
  });

i18n.loadLanguages(['fi', 'se', 'en'])

export default i18n;