import { useState, useEffect } from 'react';

function useWindowState() {

  const hasWindow = typeof window !== 'undefined';

  const [windowState, setWindowState] = useState(
    {
      width: hasWindow ? window.innerWidth : null,
      height: hasWindow ? window.innerHeight : null,
      portrait: hasWindow ? window.innerWidth < window.innerHeight : null,
      landscape: hasWindow ? window.innerWidth >= window.innerHeight : null
    }
  );

  useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setWindowState(
          {
            width: hasWindow ? window.innerWidth : null,
            height: hasWindow ? window.innerHeight : null,
            portrait: hasWindow ? window.innerWidth < window.innerHeight : null,
            landscape: hasWindow ? window.innerWidth >= window.innerHeight : null
          }
        );
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);

  return windowState;
}

export default useWindowState;