import config_Common from './config_common.json';
import config_Helge from './config_Helge.json';
import config_Neste from './config_Neste.json';

const resolveEntry = (path: string, obj: any) : string|null => {
  return path.split('.').reduce(function(prev: any, curr: any) {
      return prev ? prev[curr] : null
  }, obj || this)
}

export const getCompanyConfig = (key: string, defaultValue: string = '') : string => {

  const common:any = config_Common;
  let custom:any = null;
  const configName = process.env.REACT_APP_COMPANY_NAME;

  switch(configName?.toLowerCase()){
    case 'helge': {
      custom = config_Helge;
      break;
    }
    case 'neste': {
      custom = config_Neste;
      break;
    }
  }

  let result: string|null = null;
  if (custom){
    result = resolveEntry(key, custom);
  }
  if (!result){
    result = resolveEntry(key, common);
  }
  return result || defaultValue;
}
