import { PaletteMode } from "@mui/material";
import palette_Common from './palette_common.json';
import palette_CGI from './palette_CGI.json';
import palette_Neste from './palette_Neste.json';
import palette_Helge from './palette_Helge.json';

export const getPalette = (theme: PaletteMode) => {

  const paletteCommon:any = palette_Common;
  var paletteCustom:any = null;
  const paletteName = process.env.REACT_APP_COMPANY_NAME;

  switch(paletteName?.toLowerCase()){
    case 'cgi': {
      paletteCustom = palette_CGI;
      break;
    }
    case 'neste': {
      paletteCustom = palette_Neste;
      break;
    }
    case 'helge': {
      paletteCustom = palette_Helge;
      break;
    }
  }
  const paletteInUse = paletteCustom || paletteCommon;
  const palette = theme === 'light' ? paletteInUse.light : paletteInUse.dark;

  //console.log('getPalette',theme, JSON.stringify(palette));
  return palette;
}
